import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import NotificationForm from "./PackageForm";

const AddPackage = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Package_Modal, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Package"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addPackage"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Package_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Package_Modal ? Add_Package_Modal : false}
      width={500}
      form={form}
      recordName="packageList"
    >
      <NotificationForm />
    </FormModal>
  );
};

export default AddPackage;
