import React, { useState } from "react";
import { Upload, message, Button, Spin } from "antd";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const Index = ({ type, url, table, cb, uId, recordName }) => {
  const [state, setState] = useState(false);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_ROOT_URL}/${url}`,
    data: { requestFor: table, cb: cb, uId: uId },
    headers: {
      authorization: token,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setState(true);
      }
      if (info.file.status === "done") {
        dispatch(
          DataGetAction(
            type,
            "FetchRecord",
            { query: "all" },
            "StartSpinner",
            "",
            recordName
          )
        );
        setState(false);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload disabled={state} showUploadList={false} {...props}>
      {state && (
        <Spin>
          <Button className="gx-mr-2 gx-pt-0">
            <UploadOutlined /> Import
          </Button>
        </Spin>
      )}
      {!state && (
        <Button className="gx-mr-2 gx-pt-0">
          <UploadOutlined /> Import
        </Button>
      )}
    </Upload>
  );
};

export default Index;
