import React, { useState, useEffect } from "react";
import FormModal from "components/Modal/EditFormModal";
import EmojiForm from "./EmojiForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
const EditVitamin = () => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_Emoji_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues && initialValues.emoji) {
      setChosenEmoji(initialValues.emoji);
    }
  }, [initialValues]);

  return (
    <div>
      <FormModal
        modalTitle="Edit Emoji"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editEmoji"
        ModalName="Edit_Emoji_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_Emoji_Modal ? Edit_Emoji_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="emojiList"
        extraFieldName="emoji"
        extraFieldValue={chosenEmoji}
      >
        <EmojiForm chosenEmoji={chosenEmoji} setChosenEmoji={setChosenEmoji} />
      </FormModal>
    </div>
  );
};

export default EditVitamin;
