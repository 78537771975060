import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import SelfTherapyForm from "./SelfTherapyForm";

const AddSelfTherapy = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_SelfTherapy_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Self Therapy"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addSelfTherapy"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_SelfTherapy_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_SelfTherapy_Modal ? Add_SelfTherapy_Modal : false}
      width={550}
      form={form}
      recordName="selfTherapyList"
    >
      <SelfTherapyForm />
    </FormModal>
  );
};

export default AddSelfTherapy;
