import React from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;
const SelfTherapyForm = () => {
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allDrugCategories } = curdPropsProps;
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="drugCategoryId"
          label="Drug Category"
          rules={[
            {
              required: true,
              message: "please enter the drug category!",
            },
          ]}
        >
          <Select>
            {allDrugCategories &&
              allDrugCategories.map((item) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.drugCategoryName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="drugName"
          label="Drug Name"
          rules={[
            {
              required: true,
              message: "please enter drug name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SelfTherapyForm;
