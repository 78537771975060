import React from "react";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import VitaminForm from "./VitaminForm";
import { useSelector } from "react-redux";

const EditVitamin = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Edit_Vitamin_Modal, Loader, initialValues } = curdProps;

  return (
    <FormModal
      modalTitle="Edit Vitamin"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editVitamin"
      ModalName="Edit_Vitamin_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Vitamin_Modal ? Edit_Vitamin_Modal : false}
      initialValues={initialValues}
      width={520}
      form={form}
      recordName="vitaminList"
    >
      <VitaminForm />
    </FormModal>
  );
};

export default EditVitamin;
