import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "../../../../../components/Modal/EditFormModal";
import CommunityForm from "./CommunityForm";
const EditCommunity = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const [form] = Form.useForm();
  const { EditCommunity_Modal, initialValues, Loader } = faqProps;
  const [communityColor, setCommunityColor] = useState("#0D7DD9");
  useEffect(() => {
    if (EditCommunity_Modal && initialValues && initialValues.communityColor) {
      setCommunityColor(initialValues && initialValues.communityColor);
    }
  }, [EditCommunity_Modal, initialValues]);
  return (
    <div>
      <FormModal
        modalTitle="Edit Community"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editCommunity"
        ModalName="EditCommunity_Modal"
        confirmLoading={Loader}
        recordName="communityList"
        ModalType="Hide_Modal"
        visible={EditCommunity_Modal ? EditCommunity_Modal : false}
        initialValues={initialValues}
        width={500}
        form={form}
        extraFieldName={"communityColor"}
        extraFieldValue={communityColor}
      >
        <CommunityForm
          formType="edit"
          setCommunityColor={setCommunityColor}
          communityColor={communityColor}
        />
      </FormModal>
    </div>
  );
};

export default EditCommunity;
