import React from "react";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import SymptomForm from "./SymptomsForm";
import { useSelector } from "react-redux";

const EditSymptom = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Edit_Symptom_Modal, Loader, initialValues } = curdProps;
  return (
    <FormModal
      modalTitle="Edit Symptom"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editSymptom"
      ModalName="Edit_Symptom_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Symptom_Modal ? Edit_Symptom_Modal : false}
      initialValues={initialValues}
      width={500}
      form={form}
      recordName="symptomList"
    >
      <SymptomForm />
    </FormModal>
  );
};

export default EditSymptom;
