import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import SelfTherapyForm from "./SelfTherapyForm";
const EditSelfTherapy = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_SelfTherapy_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Self Therapy"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editSelfTherapy"
        ModalName="Edit_SelfTherapy_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_SelfTherapy_Modal ? Edit_SelfTherapy_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="selfTherapyList"
      >
        <SelfTherapyForm />
      </FormModal>
    </div>
  );
};

export default EditSelfTherapy;
