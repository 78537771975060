import { updateProfile } from "./CommonFn";
const INIT_STATE = {
  token: localStorage.getItem("token"),
  initURL: "",
  authUser: JSON.parse(localStorage.getItem("userInfo")),
  loginStart: false,
  success: false,
  error: false,
  resetSuccess: false,
  resetFail: false,
  unAuthorized_modal: false,
  message: "",
  otpSuccess: false,
  otpLoader: false,
  ResetLoading: false,
  ShowSetNewLoader: false,
  ShowForgotLoader: false,
  userInfo: null,
  userRegisterSuccessfully: false,
  userRegisterFail: false,
  savingDetail: false,
  savingLoginDetail: false,
  loginDetailChangeSuccessfully: false,
  loginDetailChangeFail: false,
  loginFail: false,
  loginFailMessage: "",
  loginSuccess: false,
  logoutSuccess: false,
  ShowRestLoader: false,
  TokenSaveSuccess: false,

  changePasswordModelVisible: false,
  editProfileModalVisible: false,
  planSettingsCompleted: false,
  isPasswordChanged: false,
  planeSettings: [],
  textMessage: "",
  isPlanSettingsFail: false,
  isPlanSettingsUpdated: false,
  settingsData: [],
  fetchingLoader: false,
  profileEditeSuccess: false,
  updatingProfile: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UN_AUTHORIZED": {
      console.log("====== UN_AUTHORIZED =====");
      return {
        ...state,
        unAuthorized_modal: true,
      };
    }
    case "UN_AUTHORIZED_close": {
      return {
        ...state,
        unAuthorized_modal: false,
      };
    }

    case "logout_success": {
      localStorage.clear();
      return {
        ...state,
        logoutSuccess: true,
        initURL: "/",
        token: null,
        authUser: null,
      };
    }
    case "saveEvent": {
      localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
      return {
        ...state,
        token: action.token,
        authUser: action.userInfo,
        TokenSaveSuccess: true,
      };
    }
    case "login_SUCCESS": {
      localStorage.setItem("userInfo", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.user.token);

      return {
        ...state,
        loginFailMessage: "",
        loginSuccess: true,
        token: action.payload.user.token,
        authUser: action.payload.user,
      };
    }
    case "login_FAILURE": {
      return { ...state, loginFail: true, loginFailMessage: action.payload };
    }
    case "INIT_URL": {
      return { ...state, initURL: action.payload };
    }
    case "loginStart_START": {
      return { ...state, loginStart: true };
    }
    case "loginStart_STOP": {
      return { ...state, loginStart: false };
    }

    case "gettingProfile_START": {
      return {
        ...state,
        fetchingLoader: true,
      };
    }
    case "getProfile_SUCCESS": {
      return {
        ...state,
        settingsData: action.payload.Record,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }

    case "changePassword_Modal": {
      return {
        ...state,
        changePasswordModelVisible: !state.changePasswordModelVisible,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "EditProfile_Modal": {
      return {
        ...state,
        editProfileModalVisible: !state.editProfileModalVisible,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "planSettings_SUCCESS": {
      return {
        ...state,
        planSettingsCompleted: true,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "resetPassword_SUCCESS": {
      return {
        ...state,
        changePasswordModelVisible: !state.changePasswordModelVisible,
        isPasswordChanged: true,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
        textMessage: "your password has been changed successfully",
      };
    }
    case "changeUserPassword_SUCCESS": {
      return {
        ...state,
        ResetLoading: false,
        resetSuccess: true,
        resetFail: false,
      };
    }
    case "changeUserPassword_FAILURE": {
      return {
        ...state,
        ResetLoading: false,
        resetSuccess: false,
        resetFail: true,
      };
    }
    case "resetPassword_FAILURE": {
      return {
        ...state,
        resetSuccess: false,
        resetFail: true,
        ResetLoading: false,
        textMessage: "Request Fail Plz try agin later",
      };
    }
    case "resetSpinner_START": {
      return {
        ...state,
        ResetLoading: true,
      };
    }
    case "updateSetting_SUCCESS": {
      return {
        ...state,

        isPlanSettingsUpdated: true,
        textMessage: "Plan settings have been updated successfully",
        fetchingLoader: false,
      };
    }

    case "updateSetting_FAILURE": {
      return {
        ...state,
        isPlanSettingsFail: true,
        textMessage: action.payload.message,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "updatingProle_START": {
      return {
        ...state,
        updatingProfile: true,
      };
    }
    case "updateProfile_SUCCESS": {
      const updatedValue = updateProfile(state.authUser, action.payload.Record);
      return {
        ...state,
        editProfileModalVisible: !state.editProfileModalVisible,
        settingsData: action.payload.Record,
        authUser: { ...updatedValue },
        profileEditeSuccess: true,
        madel_profile_modal: false,
        textMessage: "Admin profile has been updated successfully",
        updatingProfile: false,
      };
    }

    case "reset_profilesettings": {
      return {
        ...state,
        isPlanSettingsFail: false,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
        isPasswordChanged: false,
        profileEditeSuccess: false,
        updatingProfile: false,
      };
    }
    case "update_auth": {
      return {
        ...state,
        success: false,
        resetSuccess: false,
        resetFail: false,
        error: false,
        message: "",
        otpSuccess: false,
        otpLoader: false,
        ShowSetNewLoader: false,
        ShowForgotLoader: false,
        userRegisterSuccessfully: false,
        userRegisterFail: false,
        savingDetail: false,
        savingLoginDetail: false,
        loginDetailChangeSuccessfully: false,
        loginDetailChangeFail: false,
        loginFail: false,
        loginSuccess: false,
        logoutSuccess: false,
        loginStart: false,
        ShowRestLoader: false,
        TokenSaveSuccess: false,
      };
    }

    default:
      return state;
  }
};
