import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import ExerciseTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditExercise from "./components/EditExercise";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddExercise from "./components/AddExercise";
import SearchBox from "components/Filters/SearchBox";
import ShowModal from "components/Modal/ShowModal";
import { renderTiming, renderArticle } from "utils/commonUseFunction";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import CallFilters from "../../../../components/Filters/SelectFilter";
const Index = () => {
  const dispatch = useDispatch();
  const filtSelectProps = useSelector((state) => state.CurdR);
  const { filtersCategory } = filtSelectProps;
  const getSelfCopingCategory = () => {
    dispatch(
      DataGetAction(
        "getSelfCopingCategory",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "filtersCategory"
      )
    );
  };

  useEffect(getSelfCopingCategory, []);

  const columns = [
    {
      title: "Exercise Name",
      render: (record) => <span>{record.title}</span>,
      key: "exerciseName",
      width: "200px",
    },
    {
      title: "Suggested Exercise No",
      render: (record) => <span>{record.suggestedExNo}</span>,
      key: "suggestedExNo",
      width: "100px",
    },
    {
      title: "Category Name",
      render: (record) => <span>{record.categoryName || "------"}</span>,
      key: "suggestedExNo",
      width: "100px",
    },
    {
      title: "Solution Description",
      render: (record) => <span>{renderArticle(record.desc, 200, 100)}</span>,
      key: "solutionDescription",
    },

    {
      title: "Solution timing",
      render: (record) => <span>{renderTiming(record.timing)}</span>,
      key: "solutionDescription",
      width: "200px",
    },

    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Exercise_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteTriggerExercise"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="exercisesList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Exercise?"
          content=""
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="EXERCISE DETAIL"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <CallFilters
              apiName="getTriggerExercise"
              recordName="exercisesList"
              width="220px"
              searchKey="category"
              options={filtersCategory}
              placeholder="Search by category"
              label="name"
              ItemValue={"_id"}
            />
            <SearchBox
              apiName="getTriggerExercise"
              recordName="exercisesList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Name/Description"
              width="220px"
            />
            <AddExercise />
          </div>
        }
      >
        <ExerciseTable
          apiName="getTriggerExercise"
          recordName="exercisesList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditExercise />
    </Layout>
  );
};
export default Index;
