import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import ConditionForm from "./ConditionForm";

const AddNewCondition = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Condition_Modal, Loader } = faqProps;
  const [communityColor, setCommunityColor] = useState("#0D7DD9");
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Condition"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addCondition"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Condition_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Condition_Modal ? Add_Condition_Modal : false}
      width={550}
      form={form}
      recordName="conditionsList"
      extraFieldName={"communityColor"}
        extraFieldValue={communityColor}
    >
      <ConditionForm setCommunityColor={setCommunityColor}
          communityColor={communityColor} />
    </FormModal>
  );
};

export default AddNewCondition;
