import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AlternateTherapyTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditSelfTherapy from "./components/EditSelfTherapy";
import TableAction from "components/Table/Actions/ActionDropdown";
import ShowModal from "components/Modal/ShowModal";
import AddSelfTherapy from "./components/AddSelfTherapy";
import SelectFilter from "components/Filters/SelectFilter";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allTriggerTypes } = curdPropsProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getTriggerTypes",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allTriggerTypes"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Self Therapy Name",
      render: (record) => <span>{record.selfTherapyName}</span>,
      key: "selfTherapyName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_SelfTherapy_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteSelfTherapy"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="selfTherapyList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this self therapy?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="SELF THERAPY"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SelectFilter
              isShowingAll={true}
              apiName="getSelfTherapy"
              SearchKey="type"
              recordName="selfTherapyList"
              options={allTriggerTypes}
              placeholder="Search by Type"
              ItemValue="_id"
              label="name"
              width="160px"
            />

            <AddSelfTherapy />
          </div>
        }
      >
        <AlternateTherapyTable
          apiName="getSelfTherapy"
          recordName="selfTherapyList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditSelfTherapy />
    </Layout>
  );
};
export default Index;
