import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "../../../../../components/Modal/EditFormModal";
import GamificationForm from "./GamificationForm";
const EditGamification = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const [initialData, setInitialData] = useState(null);
  const [form] = Form.useForm();
  const { Edit_Gamification_Setting_Modal, initialValues, Loader } = faqProps;
  const [photoList, setPhotoState] = useState({ loading: false, imageUrl: "" });
  const [switchValue, setSwitchValue] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.imageUrl) {
      setPhotoState({ imageUrl: initialValues.imageUrl });
    }
    if (initialValues && initialValues.isAbove === true) {
      setSwitchValue(initialValues.isAbove);
    }

    if (initialValues) {
      setInitialData(initialValues);
    }
    if (!Edit_Gamification_Setting_Modal) {
      setInitialData(null);
      setSwitchValue(false);
      form.resetFields();
    }
  }, [Edit_Gamification_Setting_Modal, initialValues]);
  return (
    <FormModal
      modalTitle="Edit Gamification"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editMadel"
      ModalName="Edit_Gamification_Setting_Modal"
      confirmLoading={Loader}
      recordName="gamificationSettingList"
      ModalType="Hide_Modal"
      visible={
        Edit_Gamification_Setting_Modal
          ? Edit_Gamification_Setting_Modal
          : false
      }
      initialValues={initialData}
      width={500}
      form={form}
      extraFieldName="imageUrl"
      extraFieldValue={photoList.imageUrl}
      secondExtraFieldName="isAbove"
      secondExtraFieldValue={switchValue}
    >
      <GamificationForm
        form={form}
        setPhotoState={setPhotoState}
        photoList={photoList}
        setSwitchValue={setSwitchValue}
        switchValue={switchValue}
      />
    </FormModal>
  );
};

export default EditGamification;
