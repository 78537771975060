import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import DrugNameTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditDrugName from "./components/EditDrugName";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddDrugName from "./components/AddDrugName";
import ShowModal from "components/Modal/ShowModal";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
import Filters from "components/Filters/SearchAndSelectFilter";
const Index = () => {
  const dispatch = useDispatch();
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allDrugCategories } = curdPropsProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getDrugCategory",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allDrugCategories"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Drug Category",
      render: (record) => <span>{record.drugCategoryName}</span>,
      key: "drugCategoryName",
      width: "300px",
    },
    {
      title: "Name",
      render: (record) => <span>{record.drugName}</span>,
      key: "drugName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_DrugName_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteCharacterOfPain"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="drugNameList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this drug?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Drug Name"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getDrugName"
              recordName="drugNameList"
              isShowAll={true}
              searchCustomQuery={{ query: "all" }}
              searchPlaceHolder="Search By Name"
              selectLabel="drugCategoryName"
              selectItemValue="_id"
              selectPlaceHolder="Search by Drug Category"
              options={allDrugCategories}
              selectSearchKey="drugType"
              selectInputWidth={150}
              searchInputWidth={200}
            />

            <AddDrugName />
          </div>
        }
      >
        <DrugNameTable
          apiName="getDrugName"
          recordName="drugNameList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditDrugName />
    </Layout>
  );
};
export default Index;
