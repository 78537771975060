import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AlternateTherapyTable from "components/Table/List";
import EditModenatoePage from "./components/EditModenatoePage";
import TableAction from "components/Table/Actions/ActionDropdown";
import ShowModal from "components/Modal/ShowModal";
import AddModenatorPage from "./components/AddModenatorPage";
import { renderArticle } from "utils/commonUseFunction";
const Index = () => {
  const columns = [
    {
      title: "Page",
      render: (record) => (
        <span className="gx-text-capitalize">{record.type}</span>
      ),
      key: "page",
      width: "200px",
    },
    {
      title: "Title",
      render: (record) => <span>{record.title}</span>,
      key: "notification",
      width: "250px",
    },
    {
      title: "Description",
      render: (record) => (
        <span>{renderArticle(record.description, 100, 65)}</span>
      ),
      key: "description",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_ModeratorPage_Modal"}
          buttonName="Edit"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Moderator Page"
        extra={<AddModenatorPage />}
      >
        <AlternateTherapyTable
          apiName="getAllStaticPage"
          recordName="moderatorPageList"
          scroll={true}
          pagination={true}
          columns={columns}
          customQuery={{ portal: "moderator" }}
        />
      </Widget>
      <EditModenatoePage />
    </Layout>
  );
};
export default Index;
