import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import QuestionForm from "./QuestionForm";

const AddQuestion = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Question_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Question"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addTriggerQuestion"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Question_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Question_Modal ? Add_Question_Modal : false}
      width={550}
      form={form}
      recordName="questionList"
    >
      <QuestionForm />
    </FormModal>
  );
};

export default AddQuestion;
