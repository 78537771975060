import React from "react";
import { Row, Col, Form, Input } from "antd";
const SpecialistForm = () => {
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="fullName"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Enter valid email",
            },
            {
              required: true,
              message: "email is required",
            },
          ]}
        >
          <Input className="gx-text-lowercase" />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default SpecialistForm;
