import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import SymptomForm from "./SymptomForm";

const EditSymptom = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditStaticPage_Modal, initialValues, Loader } = faqProps;
  const renderImage = initialValues && initialValues.displayImage;
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: renderImage ? renderImage : "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  return (
    <div>
      <FormModal
        modalTitle="Edit Static"
        submitButtonText={"Update"}
        cancelButtonText={"Cancel"}
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editStaticPageData"
        ModalName="EditStaticPage_Modal"
        recordName="staticPageList"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={EditStaticPage_Modal ? EditStaticPage_Modal : false}
        initialValues={initialValues}
        width={550}
        extraFieldValue={photoList.imageUrl}
        extraFieldName={"displayImage"}
      >
        <SymptomForm
          form={form}
          photoList={photoList}
          setPhotoState={setPhotoState}
          initialValues={initialValues}
        />
      </FormModal>
    </div>
  );
};

export default EditSymptom;
