import React, { useState } from "react";
import { Form, Input, Row, Col, Switch, InputNumber } from "antd";
import ImageUploader from "../../../../../components/AvatarUploader/ImageUploader";
const GamificationForm = ({
  setPhotoState,
  photoList,
  setSwitchValue,
  switchValue,
}) => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Gamification Medal"
          rules={[
            {
              required: true,
              message: "Gamification Medal is required!",
            },
          ]}
        >
          <Input placeholder="Enter Medal" />
        </Form.Item>
        <Row className="gx-flex-row">
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              name="min"
              label="Min Value"
              rules={[{ required: true }]}
            >
              <InputNumber className="gx-w-100" />
            </Form.Item>
          </Col>

          {!switchValue && (
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="max"
                label="Max Value"
                rules={[
                  { required: !switchValue ? true : false },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("min") < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Confirm Password is required");
                    },
                  }),
                ]}
              >
                <InputNumber disabled={switchValue} />
              </Form.Item>
            </Col>
          )}

          <Col
            xl={8}
            lg={8}
            md={8}
            sm={24}
            xs={24}
            className="gx-d-flex gx-align-items-center gx-pt-md-4"
          >
            <div className="gx-d-flex" style={{ gap: "10px" }}>
              <Form.Item name="isAbove" initialValue={switchValue}>
                <Switch
                  checked={switchValue}
                  onChange={(e) => {
                    setSwitchValue(e);
                  }}
                ></Switch>
              </Form.Item>
              <span className="gx-mt-2">Above</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <p className="gx-mb-2">Medal Image Uploader</p>
        <ImageUploader setPhotoState={setPhotoState} photoList={photoList} />
      </Col>
    </Row>
  );
};

export default GamificationForm;
