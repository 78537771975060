import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import GamificationTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditGamification from "./components/EditGamification";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import AddGamification from "./components/AddGamification";
import ShowModal from "components/Modal/ShowModal";
import { Image } from "antd";

const Index = () => {
  const columns = [
    {
      title: "ID",
      render: (text, record, index) => <span>{index + 1}</span>,
      key: "fullName",
    },
    {
      title: "Medal",
      render: (record) => <span>{record.title}</span>,
      key: "users",
    },
    {
      title: "Range",
      render: (record) => (
        <span>
          {record.min} - {!record.isAbove ? record.max : ""}{" "}
          {record.isAbove ? "Above" : ""}
        </span>
      ),
      key: "Status",
    },
    {
      title: "Image",
      render: (record) => <Image src={record.imageUrl} style={{width:'50px'}} />,
      key: "Status",
    },
    {
      title: "",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Gamification_Setting_Modal"}
          buttonName="Edit"
        />
        {/* <DeleteConfirm
          type="deleteGamificationSetting"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="gamificationSettingList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this record?"
          Icon="Delete"
        /> */}
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Gamification Medal"
        extra={
          <div className="gx-d-flex">
            <AddGamification />
          </div>
        }
      >
        <GamificationTable
          apiName="getMadel"
          recordName="gamificationSettingList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditGamification />
    </Layout>
  );
};
export default Index;
