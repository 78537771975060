import React, { useState } from "react";
import { Form, Cascader, Row, Col } from "antd";
import ReactQuill, { Quill } from "react-quill";
import { useSelector } from "react-redux";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
Quill.register("modules/imageUploader", ImageUploader);

const EducationForm = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { categoriesForFilter } = CurdProps;
  const [value, setValue] = useState("");
  function handleChange(value) {
    setValue(value);
  }

  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Category is required",
            },
          ]}
        >
          <Cascader
            fieldNames={{
              label: "name",
              value: "name",
              children: "children",
            }}
            options={categoriesForFilter && categoriesForFilter}
            placeholder="Please select"
          />
        </Form.Item>
      </Col>

      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="article" label="Article" initialValue="">
          <ReactQuill
            modules={{
              toolbar: [["bold", "italic", "image"]],
              imageUploader: {
                upload: async (file) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append("image", file);
                  const response = await axios({
                    method: "post",
                    url: "https://api.imgbb.com/1/upload?key=d36eb6591370ae7f9089d85875e56b22",
                    data: bodyFormData,
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  return response.data.data.url;
                },
              },
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "imageBlot", // #5 Optinal if using custom formats
            ]}
            value={value || ""}
            onChange={() => handleChange()}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EducationForm;
