import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import TipTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditTip from "./components/EditTip";
import ShowModal from "components/Modal/ShowModal";
import TableAction from "components/Table/Actions/ActionDropdown";
import Filters from "components/Filters/SearchAndSelectFilter";
import AddTip from "./components/AddTip";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allTriggerTypes } = curdPropsProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getTriggerTypes",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allTriggerTypes"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Trigger Type",
      render: (record) => <span>{record.triggerType}</span>,
      key: "triggerType",
      width: "200px",
    },
    {
      title: "Tip#",
      render: (record) => <span>{record.tipNo}</span>,
      key: "tipNumber",
      width: "80px",
    },
    {
      title: "Tip Text (A)",
      render: (record) => <span>{record.tipDesc}</span>,
      key: "tipText",
      width: "250px",
    },
    {
      title: "Tip Text (B)",
      render: (record) => <span>{record.tipDescB || "----"}</span>,
      key: "tipText",
      width: "250px",
    },
    {
      title: "Tip Text (C)",
      render: (record) => <span>{record.tipDescC || "----"}</span>,
      key: "tipText",
      width: "250px",
    },
    {
      title: "Suggested Exercise",
      render: (record) => <span>{record.suggestedExNo}</span>,
      key: "suggestedExercise",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Tips_Modal"}
          buttonName="Edit"
        />

        <DeleteConfirm
          type="deleteTip"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="tipsList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Tip?"
          content=""
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="TRIGGER Tips"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getTips"
              recordName="tipsList"
              isShowAll={true}
              searchPlaceHolder="Search By Tip Text"
              selectLabel="triggerTypeName"
              selectItemValue="_id"
              selectPlaceHolder="Search by Type"
              options={allTriggerTypes}
              selectSearchKey="type"
              selectInputWidth={150}
              searchInputWidth={200}
            />

            <AddTip />
          </div>
        }
      >
        <TipTable
          apiName="getTips"
          recordName="tipsList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditTip />
    </Layout>
  );
};
export default Index;
