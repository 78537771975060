import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import PhysicianForm from "./PhysicianForm";

const AddPhysician = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Physician_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Physician"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addPainManagementSpecialist"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Physician_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Physician_Modal ? Add_Physician_Modal : false}
      width={550}
      form={form}
      className="doctor-profile-modal"
      recordName="physicianList"
    >
      <PhysicianForm />
    </FormModal>
  );
};

export default AddPhysician;
