import React from "react";
import { Form, Input, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { SketchPicker } from "react-color";

const ConditionForm = ({ setCommunityColor, communityColor }) => {
  const { authUser } = useSelector((state) => state.auth);
  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="conditionName"
            label={"Condition Name"}
            rules={[
              {
                required: true,
                message: "please enter the condition name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        {authUser && authUser.role === "highSuperAdmin" && (
          <>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="py-2">
              <span>Community Color</span>
              <div
                style={{
                  margin: "10px 0px",
                  height: "30px",
                  width: "100%",
                  borderRadius: "10px",
                  background: communityColor,
                }}
              ></div>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item name="communityColor">
                <SketchPicker
                  color={communityColor}
                  onChange={(e) => setCommunityColor(e.hex)}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default ConditionForm;
