import React from "react";
import { Button, Modal, Form } from "antd";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  confirmLoading,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  children,
  width,
  form,
  className,
  showFooter,
}) => {
  if (!visible) {
    form.resetFields();
  }

  return (
    <div>
      <Modal
        visible={visible}
        title={modalTitle}
        width={width}
        form={form}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        okText={submitButtonText}
        cancelText={cancelButtonText}
        className={className}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        okButtonProps={{ disabled: showFooter }}
        confirmLoading={confirmLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {});
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          {children}
        </Form>
      </Modal>
    </div>
  );
};

const CollectionsPage = ({
  children,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  confirmLoading,
  apiMethod,
  addApiName,
  addApiRequest,
  visible,
  ModalType,
  width,
  className,
  apiLoader,
  addButtonShow = true,
  extraFieldName,
  secondExtraFieldName,
  secondExtraFieldValue,
  extraFieldValue,
  payload = null,
  addButtonName = "Add",
  showFooter,
  ModalName,
  recordName,
  form,
}) => {
  const dispatch = useDispatch();
  const onCreate = (values) => {
    if (values.email) {
      values.email = values.email.toLowerCase();
    }

    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue;
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue;
    }

    dispatch(
      DataRequestAction(
        apiMethod,
        addApiName,
        addApiRequest,
        values,
        apiLoader,
        ModalName,
        recordName
      )
    );
  };

  return (
    <div>
      {addButtonShow && (
        <Button
          className="gx-pt-0 gx-mb-0"
          type="primary"
          onClick={() => {
            dispatch({
              type: "Show_Modal",
              payload: payload,
              ModalName: ModalName,
            });
          }}
        >
          {addButtonName}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        confirmLoading={confirmLoading}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        showFooter={showFooter}
        onCreate={onCreate}
        form={form}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          });
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  );
};

export default CollectionsPage;
