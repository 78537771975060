import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import AlternateTherapyForm from "./AlternateTherapyForm";
const EditAlternateTherapy = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_AlternateTherapy_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="EDIT NATURE OF VISIT"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editAlternateTherapy"
        ModalName="Edit_AlternateTherapy_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={
          Edit_AlternateTherapy_Modal ? Edit_AlternateTherapy_Modal : false
        }
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="alternateTherapyList"
      >
        <AlternateTherapyForm />
      </FormModal>
    </div>
  );
};

export default EditAlternateTherapy;
