import React from "react";
import { Button, Form, Select } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
import Input from "antd/lib/input/Input";
import { SearchOutlined } from "@ant-design/icons";
const CallFilters = ({
  showAll,
  selectPlaceHolder,
  options,
  ItemValue,
  width = "160px",
  label,
  secondItem,
  secondLabel,
  apiName,
  searchPlaceHolder,
  isShowingAll,
  className,
  secondOptions,
  defaultValue,
  recordName,
  customQuery = {},
  setFilterValue,
  useSetFilterValue = false,
}) => {
  const dispatch = useDispatch();

  const fetchList = (values) => {
    const query = {
      query: "search",
    };
    if (useSetFilterValue) {
      setFilterValue({...values, ...query});
    }
    if (values.value === "all") {
      query.query = "all";
      if (showAll) {
        return showAll();
      }
    }
    dispatch(
      DataGetAction(
        apiName,
        "FetchRecord",
        { ...values, ...query, ...customQuery },
        "searching",
        "",
        recordName
      )
    );
  };

  return (
    <Form layout="inline" onFinish={fetchList}>
      <Form.Item name="condition">
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          style={{ width: width }}
          placeholder="Condition Name"
          defaultValue={defaultValue}
          className={className}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {isShowingAll && <Select.Option value="all">All</Select.Option>}
          {secondOptions &&
            secondOptions.map((Item) => {
              return (
                <Select.Option value={Item[`${secondItem}`]}>
                  {Item[`${secondLabel}`]}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name="keyword">
        <Input
          className="gx-mb-0 gx-h-100"
          placeholder={searchPlaceHolder}
          style={{ width: width }}
        />
      </Form.Item>
      <Form.Item name="category">
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          style={{ width: width }}
          placeholder={selectPlaceHolder}
          defaultValue={defaultValue}
          className={className}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {isShowingAll && <Select.Option value="all">All</Select.Option>}
          {options &&
            options.map((Item) => {
              return (
                <Select.Option value={Item[`${ItemValue}`]}>
                  {Item[`${label}`]}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button className="gx-py-0 gx-px-2" type="primary" htmlType="submit">
          <SearchOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CallFilters;
