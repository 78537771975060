import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import NatureOfVisitTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditNatureOfVisit from "./components/EditNatureOfVisit";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddNatureOfVisit from "./components/AddNatureOfVisit";
import UploadCSV from "components/uploadCSV";
import SearchBox from "components/Filters/SearchBox";
import ShowModal from "components/Modal/ShowModal";
import { Tag } from "antd";

const Index = () => {
  const columns = [
    {
      title: "Name",
      render: (record) => <span>{record.natureOfVisitName}</span>,
      key: "symptomName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_NatureOfVisit_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteNatureOfVisit"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="natureOfVisitList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this nature of visit?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <div className="gx-d-flex gx-flex-column">
            <span>NATURE OF VISIT</span>
            <a
              className="gx-m-0"
              href="https://painles.s3.amazonaws.com/1654281018288-natureOfVisitName-1%20-%20Sheet1.csv"
            >
              <Tag className="gx-m-0" color="blue">
                Download CSV Sample
              </Tag>
            </a>
          </div>
        }
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getNatureOfVisit"
              recordName="natureOfVisitList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Name"
              width="200px"
            />
            <UploadCSV
              type="getNatureOfVisit"
              url="bulkImportSkills"
              table="NatureOfVisit"
              recordName="natureOfVisitList"
              cb="natureOfVisitName"
              uId="natureOfVisitId"
            />
            <AddNatureOfVisit />
          </div>
        }
      >
        <NatureOfVisitTable
          apiName="getNatureOfVisit"
          recordName="natureOfVisitList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditNatureOfVisit />
    </Layout>
  );
};
export default Index;
