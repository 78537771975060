import React, { useState } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import SpecialistForm from "./speciallistForm";
const EditSpecialist = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditSpecialist_Modal, initialValues, Loader } = faqProps;
  console.log("========initialValues=====", initialValues);
  const profile = initialValues && initialValues.profileImageUrl;
  const locationImg = initialValues && initialValues.locationImage;
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: profile ? profile : "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const [location, setLocation] = useState({
    imageUrl: locationImg ? locationImg : "",
    loading: false,
  });
  return (
    <div>
      <FormModal
        modalTitle="Edit Specialist"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editSpecialist"
        ModalName="EditSpecialist_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={EditSpecialist_Modal ? EditSpecialist_Modal : false}
        initialValues={initialValues}
        width={850}
        recordName="specialistList"
        form={form}
        extraFieldName="profileImageUrl"
        extraFieldValue={photoList.imageUrl}
        secondExtraFieldName="locationImage"
        secondExtraFieldValue={location.imageUrl}
      >
        <SpecialistForm
          photoList={photoList}
          setPhotoState={setPhotoState}
          location={location}
          setLocation={setLocation}
        />
      </FormModal>
    </div>
  );
};

export default EditSpecialist;
