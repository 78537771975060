import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tree } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import EditForm from "./EditForm";

const { DirectoryTree } = Tree;
const EditCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_Category_model, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  const iniImage = initialValues && initialValues.categoryImage;

  let initialState = {
    loading: false,
    imageUrl: iniImage ? iniImage : "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (initialValues) {
      initialValues["key"] = "parent_" + initialValues.orderNo;
      initialValues["title"] = initialValues.name;

      if (initialValues.children && initialValues.children.length > 0) {
        initialValues.children.forEach((item, index) => {
          let parentKey = item.id;
          item["key"] = parentKey;
          item["title"] = item.name;

          if (item.children) {
            item.children.forEach((Item) => {
              let childKey = Item.id;
              Item["key"] = parentKey + "-" + childKey;
              Item["title"] = Item.name;

              if (Item.children) {
                Item.children.forEach((singleItem) => {
                  let grandChildKey = singleItem.id;
                  singleItem["key"] =
                    parentKey + "-" + childKey + "-" + grandChildKey;
                  singleItem["title"] = singleItem.name;
                });
              }
            });
          }
        });
      }
      setTreeData([initialValues]);
    }
  }, [initialValues]);

  const renderTreeData = () => {
    if (treeData && treeData.length > 0) {
      return <DirectoryTree showLine defaultExpandAll treeData={treeData} />;
    } else {
      return <div className="gx-text-center">No Data added yet</div>;
    }
  };

  return (
    <FormModal
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editMedicalConditionCategory"
      ModalName="Edit_Category_model"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Category_model ? Edit_Category_model : false}
      initialValues={initialValues}
      width={900}
      form={form}
      recordName="categoryList"
      extraFieldValue={photoList.imageUrl}
      extraFieldName={"categoryImage"}
    >
      <Row className="gx-flex-row">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="gx-px-0"
          style={{ borderRight: "1px solid #C8C8C8" }}
        >
          <div className="model-header">
            <span style={{ color: "#ffffff" }}>Edit Category</span>
          </div>
          <EditForm photoList={photoList} setPhotoState={setPhotoState} />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
          <div className="model-header-right">
            <span style={{ color: "#ffffff" }}>Perview</span>
          </div>
          {renderTreeData()}
        </Col>
      </Row>
    </FormModal>
  );
};

export default EditCategory;
