import React, { useState } from "react";
import { Col, Form, Row, Input, Select } from "antd";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
Quill.register("modules/imageUploader", ImageUploader);
const { Option } = Select;
const ModenatoePageForm = () => {
  const [value, setValue] = useState("");
  function handleChange(value) {
    setValue(value);
  }

  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="type"
          label="Page"
          rules={[
            {
              required: true,
              message: "Page is required!",
            },
          ]}
        >
          <Select>
            <Option value="privacyPolicy">Privacy Policy</Option>
            <Option value="termAndConditions">Term and conditions</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" initialValue="">
          <ReactQuill
            modules={{
              toolbar: [["bold", "italic", "image"]],
              imageUploader: {
                upload: async (file) => {
                  const bodyFormData = new FormData();
                  bodyFormData.append("image", file);
                  const response = await axios({
                    method: "post",
                    url: "https://api.imgbb.com/1/upload?key=d36eb6591370ae7f9089d85875e56b22",
                    data: bodyFormData,
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  return response.data.data.url;
                },
              },
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "imageBlot",
            ]}
            value={value || ""}
            onChange={() => handleChange()}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ModenatoePageForm;
