import React, { useState } from "react";
import { Form, Cascader, Row, Col, Select } from "antd";
import ReactQuill, { Quill } from "react-quill";
import { useSelector } from "react-redux";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
Quill.register("modules/imageUploader", ImageUploader);
const { Option } = Select;
const ArticleForm = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { allCategoriesList, conditionList } = CurdProps;
  const [value, setValue] = useState("");
  function handleChange(value) {
    setValue(value);
  }
  var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ indent: "-1" }, { indent: "+1" }],

    [{ align: [] }],
    [{ direction: "rtl" }],
    [{ script: "sub" }, { script: "super" }],
    ["clean"],
  ];

  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="condition"
            label="Condition"
            rules={[
              {
                required: true,
                message: "Condition is required",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {conditionList &&
                conditionList.map((item) => (
                  <Option value={item._id}>{item.conditionName}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: "Category is required",
              },
            ]}
          >
            <Cascader
              fieldNames={{
                label: "name",
                value: "id",
                children: "children",
              }}
              options={allCategoriesList && allCategoriesList}
              placeholder="Please select"
            />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="article"
            label="Article"
            rules={[
              {
                required: true,
                message: "Article is required",
              },
            ]}
            initialValue=""
          >
            <ReactQuill
              modules={{
                toolbar: toolbarOptions,
                imageUploader: {
                  upload: async (file) => {
                    const bodyFormData = new FormData();
                    bodyFormData.append("image", file);
                    const response = await axios({
                      method: "post",
                      url: "https://api.imgbb.com/1/upload?key=d36eb6591370ae7f9089d85875e56b22",
                      data: bodyFormData,
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    });
                    return response.data.data.url;
                  },
                },
              }}
              value={value || ""}
              onChange={() => handleChange()}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ArticleForm;
