import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import VitaminForm from "./VitaminForm";

const AddNewVitamin = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Add_Vitamin_Modal, Loader } = curdProps;

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Vitamin"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addVitamin"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Vitamin_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Vitamin_Modal ? Add_Vitamin_Modal : false}
      width={630}
      recordName="vitaminList"
      form={form}
    >
      <VitaminForm />
    </FormModal>
  );
};

export default AddNewVitamin;
