import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import CategoryForm from "./CategoryForm";

const AddNewCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { AddCategory_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Category"
 
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addExerciseCategory"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="AddCategory_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={AddCategory_Modal ? AddCategory_Modal : false}
      width={550}
      form={form}
      recordName="exerciseCategoryList"
    >
      <CategoryForm />
    </FormModal>
  );
};

export default AddNewCategory;
