import React from "react";
import { Form, Input, Button, Space, Row, Col, InputNumber } from "antd";

import Uploader from "components/AvatarUploader/CategoryImageUploader";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import uuid from "react-uuid";
const CategoryForm = ({ photoList, setPhotoState }) => {
  const renderSubChildCategory = (name, parentName) => {
    return (
      <Col xl={24} lg={24} md={24}>
        <Form.List name={[name, "children"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row>
                  <Col xl={24} lg={24} md={24}>
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        fieldKey={[fieldKey, "id"]}
                        initialValue={uuid()}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label={
                          <>
                            {`${parentName}_${key + 1}`}{" "}
                            <MinusCircleOutlined
                              className="gx-text-red gx-mx-2"
                              onClick={() => remove(name)}
                            />
                          </>
                        }
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        style={{
                          width: "200px",
                        }}
                      >
                        <Input placeholder="Child Category Name" />
                      </Form.Item>
                    </Space>
                  </Col>
                  {renderSubChildCategory(name, `${parentName}_${key + 1}`)}
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className="gx-pt-0"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {`Add Child  ${parentName}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    );
  };

  const renderChildCategory = (name, parentName) => {
    return (
      <Row>
        <Col xl={24} lg={24} md={24}>
          <Form.List name={[name, "children"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    <Row>
                      <Col xl={24} lg={24} md={24}>
                        <div className="gx-d-flex gx-flex-row">
                          <div className="gx-mr-2">
                            <Form.Item
                              {...restField}
                              name={[name, "id"]}
                              fieldKey={[fieldKey, "id"]}
                              initialValue={uuid()}
                              className="gx-d-none"
                            >
                              <Input disabled />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              label={
                                <>
                                  {`${parentName}_${key + 1}`}{" "}
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    className="gx-text-red gx-mx-2"
                                  />
                                </>
                              }
                              name={[name, "name"]}
                              fieldKey={[fieldKey, "name"]}
                              style={{
                                width: "200px",
                              }}
                            >
                              <Input placeholder="Child Category Name" />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      {renderSubChildCategory(name, `${parentName}_${key + 1}`)}
                    </Row>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-pt-0"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {`Add Child ${parentName}`}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    );
  };

  const renderCategoryChild = (parentName) => {
    return (
      <Form.List name="children">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => {
              return (
                <div key={key}>
                  <Row className="gx-flex-row gx-ml-0">
                    <Col
                      className="gx-ml-0 gx-mr-0 gx-px-0"
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        label={key}
                        fieldKey={[fieldKey, "id"]}
                        initialValue={uuid()}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "categoryImage"]}
                        fieldKey={[fieldKey, "categoryImage"]}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={
                          <>
                            {`${parentName}_${key + 1}`}
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                              className="gx-mx-2 gx-text-red"
                            />
                          </>
                        }
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Subcategory name",
                          },
                        ]}
                      >
                        <Input placeholder="Subcategory Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {renderChildCategory(name, `${parentName}_${key + 1}`)}
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                className="gx-pt-0"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Subcategory
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="orderNo" label="Order No">
            <InputNumber min={0} className="gx-w-100" />
          </Form.Item>
          <Row className="gx-flex-row">
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
              <Form.Item
                name="name"
                label="Category Name"
                rules={[
                  {
                    required: true,
                    message: "Category Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="categoryImage" label="Category Image">
                <Uploader photoList={photoList} setPhotoState={setPhotoState} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col
          className="category-columns"
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ overflow: "auto" }}
        >
          {renderCategoryChild("Category")}
        </Col>
      </Row>
    </div>
  );
};

export default CategoryForm;
