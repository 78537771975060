import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";

const DetailSubjectModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { Open_Subject_Modal, initialValues } = CurdProps || {};

  return (
    <>
      <SimpleModal
        visible={Open_Subject_Modal ? Open_Subject_Modal : false}
        modalFor="Open_Subject_Modal"
        width={500}
        title={"Subject & Text"}
      >
        <p
          className="dangerous-text"
          dangerouslySetInnerHTML={{ __html: initialValues && initialValues }}
        ></p>
      </SimpleModal>
    </>
  );
};

export default DetailSubjectModal;
