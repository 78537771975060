import React from "react";
import { Input } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";

const Index = ({
  apiName,
  requestType,
  placeholder,
  customQuery = {},
  recordName,
  width,
  setFilterValue,
  useSetFilterValue = false,
}) => {
  const dispatch = useDispatch();
  // const searchRecord = (e) => {};

  const onSearch = (value) => {
    if (useSetFilterValue) {
      setFilterValue({ ...customQuery, name: value, });
    }

    dispatch(
      DataGetAction(
        apiName,
        requestType,
        { name: value, ...customQuery },
        "StartSpinner",
        "",
        recordName
      )
    );
  };

  return (
    <Input.Search
      className="gx-mb-0 gx-h-100"
      onSearch={onSearch}
      placeholder={placeholder}
      // onChange={(e) => searchRecord(e.target.value)}
      style={{ width: width }}
    />
  );
};

export default Index;
