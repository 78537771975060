import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import SymptomForm from "./SymptomsForm";

const AddNewSymptom = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Add_Symptom_Modal, Loader } = curdProps;

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Symptom"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addSymptom"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Symptom_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Symptom_Modal ? Add_Symptom_Modal : false}
      width={500}
      recordName="symptomList"
      form={form}
    >
      <SymptomForm />
    </FormModal>
  );
};

export default AddNewSymptom;
