import React from "react";
import { Col, Form, Row, InputNumber, Button, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const SelfTherapyForm = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              message: "Price is required!",
            },
          ]}
        >
          <InputNumber className="gx-w-100" />
        </Form.Item>
        <Form.List className="gx-w-100" name="description">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  className="gx-d-flex gx-w-100 gx-align-items-center"
                  style={{ gap: "10px" }}
                >
                  <MinusCircleOutlined
                    width={"8%"}
                    className=""
                    onClick={() => remove(name)}
                  />
                  <Form.Item
                    {...restField}
                    name={[name]}
                    label={`Description ${key + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "field is required",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className="gx-py-0"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default SelfTherapyForm;
