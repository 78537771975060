import React from "react";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import Filters from "components/Filters/SearchAndSelectFilter";
import { renderStatus } from "utils/commonUseFunction";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditUser from "./components/EditUser";
import ResetPassword from "./components/ResetPassword";
const User = () => {
  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.userId}</span>,
      key: "ID",
      width: "100px",
    },

    {
      title: "FullName",
      render: (record) => (
        <span>
          {(record.firstName || "--") + " " + (record.lastName || "--")}
        </span>
      ),
      key: "firstName",
      width: "150px",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email}</span>,
      key: "Email",
      width: "200px",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "Status",
    },
    {
      title: " ",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column gx-pointer ">
        {record.status !== "block" && record.status !== "deleted" && (
          <DeleteConfirm
            type="editUsers"
            recordName="userList"
            method="PUT"
            selectedItem={{ _id: record._id, status: "block" }}
            apiRequest="EditRecord"
            buttonName="Yes"
            oKText="Block"
            title="Are you sure you want to block this User?"
            Icon="Block"
          />
        )}
        {record.status !== "active" && (
          <DeleteConfirm
            type="editUsers"
            recordName="userList"
            method="PUT"
            selectedItem={{ _id: record._id, status: "active" }}
            apiRequest="EditRecord"
            buttonName="Yes"
            oKText="Active"
            title="Are you sure you want to active this User?"
            Icon="Active"
          />
        )}
        {record.status === "active" && (
          <ShowModal
            record={record}
            modalName={"Edit_User_Modal"}
            buttonName="Edit"
          />
        )}
        {record.status === "active" && (
          <ShowModal
            record={record}
            modalName={"Reset_User_Password_Modal"}
            buttonName="Reset Password"
          />
        )}
        <DeleteConfirm
          type="deleteUser"
          recordName="userList"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this User?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="USER"
        extra={
          <Filters
            apiName="getUsers"
            recordName="userList"
            isShowAll={true}
            searchPlaceHolder="Search By Name/Email"
            selectLabel="label"
            selectItemValue="status"
            selectPlaceHolder="Search by Status"
            options={[
              { status: "active", label: "Active" },
              { status: "block", label: "Block" },
            ]}
            selectSearchKey="status"
            selectInputWidth={150}
            searchInputWidth={200}
          />
        }
      >
        <UserList
          recordName="userList"
          apiName="getUsers"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <ResetPassword />
      <EditUser />
    </Layout>
  );
};

export default User;
