import React, { useState } from "react";
import FormModal from "components/Modal/FormModal";
import CategoryForm from "./CategoryForm";
import { Form } from "antd";
import { useSelector } from "react-redux";

const AddNewCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { AddCategory_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Category"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addCategory"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="AddCategory_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={AddCategory_Modal ? AddCategory_Modal : false}
      width={900}
      recordName="categoryList"
      form={form}
      extraFieldValue={photoList.imageUrl}
      extraFieldName={"categoryImage"}
    >
      <CategoryForm photoList={photoList} setPhotoState={setPhotoState} />
    </FormModal>
  );
};

export default AddNewCategory;
