import React from "react";
import { Col, Form, Input, Row } from "antd";
const AlternateTherapy = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="alternateTherapyName"
          label="CAIT CARE Therapy Name"
          rules={[
            {
              required: true,
              message: "please enter alternate therapy name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AlternateTherapy;
