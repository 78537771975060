import React from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const ImageUploader = ({ name, setPhotoState, photoList, initialValues }) => {
  const token = localStorage.getItem("token");

  const { loading, imageUrl } = photoList;
  console.log("========= setPhotoState=========", imageUrl);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setPhotoState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      console.log("info", info);
      // Get this url from response in real world.

      setPhotoState({
        imageUrl: info.file.response.url,
        loading: false,
      });
    }
  };

  const uploadButton = (
    <div className="uploaderImageCategory">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-center">
          <div>
            <span
              className="icon icon-image gx-d-block"
              style={{
                fontSize: "20px",
                opacity: "0.3",
                marginBottom: "-2px",
                marginRight: "10px",
              }}
            ></span>
          </div>
          <span style={{ fontSize: "16px" }}>Drop Image Here</span>
        </div>
      )}
    </div>
  );

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { file: name },
    onChange: handleChange,
    multiple: false,
    listType: "picture-card",
    className: "avatar-uploader-Category uploaderImageCategory gx-w-100",
    showUploadList: false,
  };

  return (
    <div>
      <Upload
        name={name}
        {...ImageProps}
        className="gx-w-100 uploaderImageCategory"
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
              height: "37px",
              borderRadius: "6px",
              objectFit: "cover",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default ImageUploader;
