import React, { useEffect, useState } from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import { Tree } from "antd";

const { DirectoryTree } = Tree;
const ViewModal = () => {
  const [treeData, setTreeData] = useState([]);

  const CurdProps = useSelector((state) => state.CurdR);
  const { Open_View_Modal, initialValues } = CurdProps || {};

  const setData = () => {
    if (initialValues) {
      initialValues &&
        initialValues.children &&
        initialValues.children.forEach((item, index) => {
          let parentKey = item.id;
          item["key"] = parentKey;
          item["title"] = item.name;

          if (item.children) {
            item.children.forEach((Item) => {
              let childKey = Item.id;
              Item["key"] = parentKey + "-" + childKey;
              Item["title"] = Item.name;

              if (Item.children) {
                Item.children.forEach((singleItem) => {
                  let grandChildKey = singleItem.id;
                  singleItem["key"] =
                    parentKey + "-" + childKey + "-" + grandChildKey;
                  singleItem["title"] = singleItem.name;
                });
              }
            });
          }
        });
      console.log(
        "======== initialValues.children",
        JSON.stringify(initialValues.children)
      );
      setTreeData(initialValues.children);
    }
  };

  useEffect(setData, [initialValues]);

  return (
    <>
      <SimpleModal
        visible={Open_View_Modal ? Open_View_Modal : false}
        modalFor="Open_View_Modal"
        width={500}
        title={"Sub categories"}
      >
        {treeData && <DirectoryTree treeData={treeData} />}
      </SimpleModal>
    </>
  );
};

export default ViewModal;
