import React, { useEffect } from "react";
import { Form, Input, Row, Col, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../../redux/actions/CommonHttp";
const GamificationForm = ({formType}) => {
  const dispatch = useDispatch();
  const { GamificationTypeList } = useSelector((state) => state.CurdR);
  useEffect(() => {
    dispatch(
      DataGetAction(
        "getPointType",
        "FetchRecord",
        "",
        "",
        "",
        "GamificationTypeList"
      )
    );
  }, []);

  const renderSelectOption = () => {
    if (GamificationTypeList && GamificationTypeList.length > 0) {
      return GamificationTypeList.map((item) => {
        return <Select.Option value={item._id}>{item.title}</Select.Option>;
      });
    }
  };

  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {formType !== 'edit'&& <Form.Item
          name="type"
          label="Gamification Type"
          rules={[
            {
              required: true,
              message: "Gamification Type is required!",
            },
          ]}
        >
          <Select placeholder="Select Type">{renderSelectOption()}</Select>
        </Form.Item>}
        <Form.Item
          name="point"
          label="Gamification Point"
          rules={[
            {
              required: true,
              message: "Gamification point is required!",
            },
          ]}
        >
          <InputNumber placeholder="Enter points" className="gx-w-100"/>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GamificationForm;
