import React from "react";
import { Col, Form, Row, Input } from "antd";
const { TextArea } = Input;

const SelfTherapyForm = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="notification"
          label="Notification"
          rules={[
            {
              required: true,
              message: "Notification is required!",
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SelfTherapyForm;
