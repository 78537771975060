import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import StaticPageTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditStaticPage from "./components/EditStaticPage";
import TableAction from "components/Table/Actions/ActionDropdown";
import ShowModal from "components/Modal/ShowModal";
import Filters from "components/Filters/SearchAndSelectFilter";
import AddStaticPage from "./components/AddStaticPage";
import { useSelector } from "react-redux";
import { renderArticle } from "utils/commonUseFunction";
import { Image } from "antd";
const StaticPage = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { initialValues } = faqProps;
  const renderImage = initialValues && initialValues.displayImage;
  const columns = [
    {
      title: "Type",
      render: (record) => (
        <span className="gx-text-capitalize">{record.type}</span>
      ),
      key: "categoryId",
    },
    {
      title: "Name",
      render: (record) => <span>{record.displayName}</span>,
      key: "displayName",
    },
    {
      title: "Order #",
      render: (record) => <span>{record.orderNumber}</span>,
      key: "displayName",
    },
    {
      title: "Image",
      render: (record) => <Image width={50} src={record.displayImage} />,
      key: "displayName",
    },
    {
      title: "Description",
      render: (record) => <span>{renderArticle(record.description)}</span>,
      key: "StaticPageName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"EditStaticPage_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteStaticPageData"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="staticPageList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Static Pages"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getStaticPageData"
              recordName="staticPageList"
              isShowAll={true}
              searchPlaceHolder="Search By Name"
              selectLabel="label"
              selectItemValue="_id"
              searchCustomQuery={{ query: "all" }}
              selectPlaceHolder="Search by Type"
              options={[
                { label: "Drawer", _id: "drawer" },
                { label: "Community", _id: "community" },
              ]}
              selectSearchKey="type"
              selectInputWidth={150}
              searchInputWidth={200}
            />
            <AddStaticPage />
          </div>
        }
      >
        <StaticPageTable
          recordName="staticPageList"
          apiName="getStaticPageData"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      {renderImage !== null && <EditStaticPage />}
    </Layout>
  );
};
export default StaticPage;
