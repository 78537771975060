import React from "react";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import ModeratorForm from "./ModeratorForm";
import { useSelector } from "react-redux";

const EditModerator = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Edit_Moderator_Modal, Loader, initialValues } = curdProps;

  return (
    <FormModal
      modalTitle="Edit Moderator"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editModerator"
      ModalName="Edit_Moderator_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Moderator_Modal ? Edit_Moderator_Modal : false}
      initialValues={initialValues}
      width={520}
      form={form}
      recordName="moderatorList"
    >
      <ModeratorForm />
    </FormModal>
  );
};

export default EditModerator;
