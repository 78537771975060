import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import EmojisTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditModal from "./components/EditEmoji";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddEmoji from "./components/AddEmoji";
import SearchBox from "components/Filters/SearchBox";
import ShowModal from "components/Modal/ShowModal";

const Index = () => {
  const columns = [
    {
      title: "Emoji",
      render: (record) => <span>{record.emoji && record.emoji.emoji}</span>,
      key: "categoryId",
      width: "100px",
    },
    {
      title: "Pain Level",
      render: (record) => <span>{record.painLevel}</span>,
      key: "categoryId",
      width: "200px",
    },
    {
      title: "Name",
      render: (record) => <span>{record.emojiName}</span>,
      key: "symptomName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Emoji_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteEmoji"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="emojiList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this emoji?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Emojis"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getEmojis"
              recordName="emojiList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Name"
              width="200px"
            />
            <AddEmoji />
          </div>
        }
      >
        <EmojisTable
          apiName="getEmojis"
          recordName="emojiList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditModal />
    </Layout>
  );
};
export default Index;
