import React from "react";
import IntlMessages from "util/IntlMessages";

const MenuList = {
  superAdmin: [
    {
      title: <IntlMessages id="leftMenu.dashboard" />,
      url: "/dashboard",
      icon: "dasbhoard",
      top: "4px",
      permissionKey: "dashboard",
      key: "superAdmin",
    },
    {
      title: "Self coping tool",
      url: "",
      icon: "listing-dbrd",
      key: "selfCopingTool",
      subMenu: [
        {
          title: "Category",
          url: "/selfCopingTool/category",
          icon: "",
          key: "category",
        },
        {
          title: "Exercise Detail",
          url: "/selfCopingTool/exerciseDetail",
          icon: "",
          key: "exerciseDetail",
        },
      ],
    },
    {
      title: "Journal",
      url: "",
      icon: "product-list",
      permissionKey: "journal",
      key: "journal",
      subMenu: [
        {
          title: "Character Of Pain",
          url: "/journal/characterOfPain",
          icon: "",
          key: "characterOfPain",
        },
        {
          title: "Triggers",
          url: "",
          icon: "",
          permissionKey: "trigger",
          key: "trigger",
          subMenu: [
            {
              title: "Types",
              url: "/journal/trigger/types",
              icon: "",
              permissionKey: "types",
              key: "types",
            },
            {
              title: "Questions",
              url: "/journal/trigger/questions",
              icon: "",
              permissionKey: "questions",
              key: "questions",
            },
            {
              title: "Tips",
              url: "/journal/trigger/tips",
              icon: "",
              key: "tips",
            },
          ],
        },

        {
          title: "Physician Care",
          url: "",
          icon: "",
          key: "painManagementSpecialist",
          subMenu: [
            {
              title: "Physician",
              url: "/journal/painManagementSpecialist/physician",
              icon: "",
              key: "physician",
            },
            {
              title: "Nature of visit",
              url: "/journal/painManagementSpecialist/natureOfVisit",
              icon: "",
              key: "natureOfVisit",
            },
          ],
        },

        {
          title: "CAIT Care",
          url: "",
          icon: "",
          key: "CAITCare",
          subMenu: [
            {
              title: "CAIT Specialist",
              url: "/journal/CAITCare/conservativeCare",
              icon: "",
              key: "CAITCare",
            },
            {
              title: "Nature of visit",
              url: "/journal/CAITCare/alternateTherapy",
              icon: "",
              key: "CAITCare",
            },
          ],
        },
        // {
        //   title: "Conservative Care",
        //   url: "/journal/conservativeCare",
        //   icon: "",
        //   top: "4px",
        //   permissionKey: "conservativeCare",
        //   key: "conservativeCare",
        // },
        // {
        //   title: "Alternate Therapy",
        //   url: "/journal/alternateTherapy",
        //   icon: "",
        //   top: "4px",
        //   permissionKey: "alternateTherapy",
        //   key: "alternateTherapy",
        // },
        {
          title: "Self Therapy",
          url: "/journal/selfTherapy",
          icon: "",
          top: "4px",
          permissionKey: "selfTherapy",
          key: "selfTherapy",
        },
        {
          title: "Drug Therapy",
          url: "",
          icon: "",
          key: "drugTherapy",
          subMenu: [
            {
              title: "Drug Category",
              url: "/journal/drugTherapy/drugCategory",
              icon: "",
              key: "drugCategory",
            },
            {
              title: "Drug Name",
              url: "/journal/drugTherapy/drugName",
              icon: "",
              key: "drugName",
            },
            // {
            //   title: "Over The Counter",
            //   url: "/journal/drugTherapy/otc",
            //   icon: "",
            //   key: "otc",
            // },
          ],
        },
        {
          title: "Emoji",
          url: "/journal/emoji",
          icon: "",
          top: "4px",
          permissionKey: "emoji",
          key: "emoji",
        },
      ],
    },
    {
      title: "Specialist",
      url: "/specialist",
      icon: "avatar",
      top: "4px",
      permissionKey: "specialist",
      key: "specialist",
    },
    {
      title: "General Education",
      url: "",
      icon: "graduation",
      top: "4px",
      permissionKey: "education",
      key: "education",
      subMenu: [
        {
          title: "Topic",
          url: "/education/topic",
          icon: "",
          top: "4px",
          permissionKey: "topic",
          key: "topic",
        },
        {
          title: "Category",
          url: "/education/category",
          icon: "",
          top: "4px",
          permissionKey: "category",
          key: "category",
        },
      ],
    },
    {
      title: "Setting",
      url: "",
      icon: "setting",
      top: "4px",
      permissionKey: "setting",
      key: "setting",
      subMenu: [
        {
          title: "Exercise",
          icon: "",
          permissionKey: "exercise",
          key: "exercise",
          subMenu: [
            {
              title: "Category",
              url: "/setting/exercise/category",
              icon: "",
              key: "category",
            },
            {
              title: "Exercises",
              url: "/setting/exercise/exercises",
              icon: "",
              key: "exercises",
            },
          ],
        },
        {
          title: "Static Page",
          url: "/setting/staticPages",
          icon: "",
          top: "4px",
          key: "staticPages",
        },
        {
          title: "Symptom",
          url: "/setting/symptom",
          icon: "",
          top: "4px",
          permissionKey: "symptom",
          key: "symptom",
        },
        {
          title: "Supplement",
          url: "/setting/supplement",
          icon: "",
          top: "4px",
          permissionKey: "supplement",
          key: "supplement",
        },
        {
          title: "Vitamin",
          url: "/setting/vitamin",
          icon: "",
          top: "4px",
          permissionKey: "vitamin",
          key: "vitamin",
        },
        {
          title: "Profile",
          url: "/setting/profile",
          icon: "",
          top: "4px",
          permissionKey: "profile",
          key: "profile",
        },
        {
          title: "Health Care",
          url: "",
          icon: "",
          keyTitle: "exercise",
          key: "healthCare",
          permissionKey: "healthCare",
          subMenu: [
            {
              title: "Category",
              url: "/setting/healthCare/category",
              icon: "",
              key: "healthCare/category",
            },
            {
              title: "Occupation",
              url: "/setting/healthCare/occupation",
              icon: "",
              key: "healthCare/occupation",
            },
          ],
        },
      ],
    },
    {
      title: "Gamification",
      icon: "chart-radar",
      top: "4px",
      permissionKey: "gamification",
      key: "gamification",
      subMenu: [
        {
          title: "Gamification Point",
          url: "/gamification/gamificationPoint",
          icon: "",
          top: "4px",
          permissionKey: "gamificationPoint",
          key: "gamificationPoint",
        },
        {
          title: "Gamification Medal",
          url: "/gamification/gamificationSetting",
          icon: "",
          top: "4px",
          permissionKey: "gamificationSetting",
          key: "gamificationSetting",
        },
      ],
    },
    {
      title: "Community",

      icon: "family",
      top: "4px",
      permissionKey: "community",
      key: "community",
      subMenu: [
        {
          title: "Community List",
          url: "/community/communityList",
          icon: "",
          top: "4px",
          permissionKey: "communityList",
          key: "communityList",
        },
        {
          title: "Community Discussion",
          url: "/community/communityDiscussion",
          icon: "",
          top: "4px",
          permissionKey: "communityDiscussion",
          key: "communityDiscussion",
        },
      ],
    },
    {
      title: "Team",
      url: "",
      icon: "wall",
      top: "4px",
      permissionKey: "team",
      key: "team",
      subMenu: [
        {
          title: "User",
          url: "/team/user",
          icon: "",
          top: "4px",
          permissionKey: "user",
          key: "user",
        },
        {
          title: "Moderator",
          url: "/team/moderator",
          icon: "",
          top: "4px",
          permissionKey: "moderator",
          key: "moderator",
        },
      ],
    },
    {
      title: "My Medical Condition",
      url: "",
      icon: "select",
      key: "myMedicalCondition",
      subMenu: [
        {
          title: "Condition",
          url: "/myMedicalCondition/condition",
          icon: "",
          key: "condition",
        },
        {
          title: "Category",
          url: "/myMedicalCondition/category",
          icon: "",
          key: "category",
        },
        {
          title: "Article",
          url: "/myMedicalCondition/article",
          icon: "",
          key: "article",
        },
      ],
    },
    {
      title: "Portal Pages",
      url: "",
      icon: "listing-dbrd",
      key: "portal",
      subMenu: [
        {
          title: "Moderator Page",
          url: "/portal/moderatorPage",
          icon: "",
          key: "moderator",
        },
        {
          title: "Specialist Page",
          url: "/portal/specialistPage",
          icon: "",
          key: "specialist",
        },
      ],
    },
    {
      title: "App Notification",
      url: "/appNotification",
      icon: "notification",
      key: "myMedicalCondition",
    },
    {
      title: "Package",
      url: "/package",
      icon: "pricing-table",
      key: "package",
    },
  ],
  highSuperAdmin: [
    {
      title: <IntlMessages id="leftMenu.dashboard" />,
      url: "/dashboard",
      icon: "dasbhoard",
      top: "4px",
      permissionKey: "dashboard",
      key: "superAdmin",
    },
    {
      title: "Self coping tool",
      url: "",
      icon: "listing-dbrd",
      key: "selfCopingTool",
      subMenu: [
        {
          title: "Category",
          url: "/selfCopingTool/category",
          icon: "",
          key: "category",
        },
        {
          title: "Exercise Detail",
          url: "/selfCopingTool/exerciseDetail",
          icon: "",
          key: "exerciseDetail",
        },
      ],
    },
    {
      title: "Journal",
      url: "",
      icon: "product-list",
      permissionKey: "journal",
      key: "journal",
      subMenu: [
        {
          title: "Character Of Pain",
          url: "/journal/characterOfPain",
          icon: "",
          key: "characterOfPain",
        },
        {
          title: "Triggers",
          url: "",
          icon: "",
          permissionKey: "trigger",
          key: "trigger",
          subMenu: [
            {
              title: "Types",
              url: "/journal/trigger/types",
              icon: "",
              permissionKey: "types",
              key: "types",
            },
            {
              title: "Questions",
              url: "/journal/trigger/questions",
              icon: "",
              permissionKey: "questions",
              key: "questions",
            },
            {
              title: "Tips",
              url: "/journal/trigger/tips",
              icon: "",
              key: "tips",
            },
          ],
        },

        {
          title: "Physician Care",
          url: "",
          icon: "",
          key: "painManagementSpecialist",
          subMenu: [
            {
              title: "Physician",
              url: "/journal/painManagementSpecialist/physician",
              icon: "",
              key: "physician",
            },
            {
              title: "Nature of visit",
              url: "/journal/painManagementSpecialist/natureOfVisit",
              icon: "",
              key: "natureOfVisit",
            },
          ],
        },

        {
          title: "CAIT Care",
          url: "",
          icon: "",
          key: "CAITCare",
          subMenu: [
            {
              title: "CAIT Specialist",
              url: "/journal/CAITCare/conservativeCare",
              icon: "",
              key: "CAITCare",
            },
            {
              title: "Nature of visit",
              url: "/journal/CAITCare/alternateTherapy",
              icon: "",
              key: "CAITCare",
            },
          ],
        },
        // {
        //   title: "Conservative Care",
        //   url: "/journal/conservativeCare",
        //   icon: "",
        //   top: "4px",
        //   permissionKey: "conservativeCare",
        //   key: "conservativeCare",
        // },
        // {
        //   title: "Alternate Therapy",
        //   url: "/journal/alternateTherapy",
        //   icon: "",
        //   top: "4px",
        //   permissionKey: "alternateTherapy",
        //   key: "alternateTherapy",
        // },
        {
          title: "Self Therapy",
          url: "/journal/selfTherapy",
          icon: "",
          top: "4px",
          permissionKey: "selfTherapy",
          key: "selfTherapy",
        },
        {
          title: "Drug Therapy",
          url: "",
          icon: "",
          key: "drugTherapy",
          subMenu: [
            {
              title: "Drug Category",
              url: "/journal/drugTherapy/drugCategory",
              icon: "",
              key: "drugCategory",
            },
            {
              title: "Drug Name",
              url: "/journal/drugTherapy/drugName",
              icon: "",
              key: "drugName",
            },
            // {
            //   title: "Over The Counter",
            //   url: "/journal/drugTherapy/otc",
            //   icon: "",
            //   key: "otc",
            // },
          ],
        },
        {
          title: "Emoji",
          url: "/journal/emoji",
          icon: "",
          top: "4px",
          permissionKey: "emoji",
          key: "emoji",
        },
      ],
    },
    {
      title: "Specialist",
      url: "/specialist",
      icon: "avatar",
      top: "4px",
      permissionKey: "specialist",
      key: "specialist",
    },
    {
      title: "General Education",
      url: "",
      icon: "graduation",
      top: "4px",
      permissionKey: "education",
      key: "education",
      subMenu: [
        {
          title: "Topic",
          url: "/education/topic",
          icon: "",
          top: "4px",
          permissionKey: "topic",
          key: "topic",
        },
        {
          title: "Category",
          url: "/education/category",
          icon: "",
          top: "4px",
          permissionKey: "category",
          key: "category",
        },
      ],
    },
    {
      title: "Setting",
      url: "",
      icon: "setting",
      top: "4px",
      permissionKey: "setting",
      key: "setting",
      subMenu: [
        {
          title: "Exercise",
          icon: "",
          permissionKey: "exercise",
          key: "exercise",
          subMenu: [
            {
              title: "Category",
              url: "/setting/exercise/category",
              icon: "",
              key: "category",
            },
            {
              title: "Exercises",
              url: "/setting/exercise/exercises",
              icon: "",
              key: "exercises",
            },
          ],
        },
        {
          title: "Static Page",
          url: "/setting/staticPages",
          icon: "",
          top: "4px",
          key: "staticPages",
        },
        {
          title: "Symptom",
          url: "/setting/symptom",
          icon: "",
          top: "4px",
          permissionKey: "symptom",
          key: "symptom",
        },
        {
          title: "Supplement",
          url: "/setting/supplement",
          icon: "",
          top: "4px",
          permissionKey: "supplement",
          key: "supplement",
        },
        {
          title: "Vitamin",
          url: "/setting/vitamin",
          icon: "",
          top: "4px",
          permissionKey: "vitamin",
          key: "vitamin",
        },
        {
          title: "Profile",
          url: "/setting/profile",
          icon: "",
          top: "4px",
          permissionKey: "profile",
          key: "profile",
        },
        {
          title: "Health Care",
          url: "",
          icon: "",
          keyTitle: "exercise",
          key: "healthCare",
          permissionKey: "healthCare",
          subMenu: [
            {
              title: "Category",
              url: "/setting/healthCare/category",
              icon: "",
              key: "healthCare/category",
            },
            {
              title: "Occupation",
              url: "/setting/healthCare/occupation",
              icon: "",
              key: "healthCare/occupation",
            },
          ],
        },
      ],
    },
    {
      title: "Gamification",
      icon: "chart-radar",
      top: "4px",
      permissionKey: "gamification",
      key: "gamification",
      subMenu: [
        {
          title: "Gamification Point",
          url: "/gamification/gamificationPoint",
          icon: "",
          top: "4px",
          permissionKey: "gamificationPoint",
          key: "gamificationPoint",
        },
        {
          title: "Gamification Medal",
          url: "/gamification/gamificationSetting",
          icon: "",
          top: "4px",
          permissionKey: "gamificationSetting",
          key: "gamificationSetting",
        },
      ],
    },
    {
      title: "Community",

      icon: "family",
      top: "4px",
      permissionKey: "community",
      key: "community",
      subMenu: [
        {
          title: "Community List",
          url: "/community/communityList",
          icon: "",
          top: "4px",
          permissionKey: "communityList",
          key: "communityList",
        },
        {
          title: "Community Discussion",
          url: "/community/communityDiscussion",
          icon: "",
          top: "4px",
          permissionKey: "communityDiscussion",
          key: "communityDiscussion",
        },
      ],
    },
    {
      title: "Team",
      url: "",
      icon: "wall",
      top: "4px",
      permissionKey: "team",
      key: "team",
      subMenu: [
        {
          title: "User",
          url: "/team/user",
          icon: "",
          top: "4px",
          permissionKey: "user",
          key: "user",
        },
        {
          title: "Moderator",
          url: "/team/moderator",
          icon: "",
          top: "4px",
          permissionKey: "moderator",
          key: "moderator",
        },
      ],
    },
    {
      title: "My Medical Condition",
      url: "",
      icon: "select",
      key: "myMedicalCondition",
      subMenu: [
        {
          title: "Condition",
          url: "/myMedicalCondition/condition",
          icon: "",
          key: "condition",
        },
        {
          title: "Category",
          url: "/myMedicalCondition/category",
          icon: "",
          key: "category",
        },
        {
          title: "Article",
          url: "/myMedicalCondition/article",
          icon: "",
          key: "article",
        },
      ],
    },
    {
      title: "Portal Pages",
      url: "",
      icon: "listing-dbrd",
      key: "portal",
      subMenu: [
        {
          title: "Moderator Page",
          url: "/portal/moderatorPage",
          icon: "",
          key: "moderator",
        },
        {
          title: "Specialist Page",
          url: "/portal/specialistPage",
          icon: "",
          key: "specialist",
        },
      ],
    },
    {
      title: "App Notification",
      url: "/appNotification",
      icon: "notification",
      key: "myMedicalCondition",
    },
    {
      title: "Package",
      url: "/package",
      icon: "pricing-table",
      key: "package",
    },
  ],
};

export default MenuList;
