/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { renderDate } from "utils/commonUseFunction";
import SimpleModal from "components/Modal/SimpleModal";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
const CommentModal = () => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const faqProps = useSelector((state) => state.CurdR);
  const { Open_Comment_Modal, initialValues, deleteSuccess } = faqProps;
  const commentInfo = initialValues && initialValues.commentInfo;
  const error = (index, data) => {
    Modal.error({
      title: "Are you sure you want to delete this comment?",
      onOk() {
        removeProduct(index, data);
      },
      closable: true,
    });
  };
  const removeProduct = (index, data) => {
    dispatch(
      DataRequestAction(
        "DELETE",
        "deleteComment",
        "DeleteComment",
        data,
        "",
        "",
        ""
      )
    );

    setComments([
      ...comments.slice(0, index),
      ...comments.slice(index + 1, comments.length),
    ]);
  };
  if (deleteSuccess) {
    dispatch(
      DataGetAction(
        "getMyCommunityDiscussion",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "communityDiscussionList"
      )
    );
    dispatch({ type: "ResetAll_State" });
  }
  const renderSetComments = () => {
    setComments(commentInfo);
  };

  useEffect(renderSetComments, [commentInfo]);

  return (
    <SimpleModal
      visible={Open_Comment_Modal ? Open_Comment_Modal : false}
      modalFor="Open_Comment_Modal"
      width={500}
      title="Comment"
    >
      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        //className="gx-mx-0 gx-d-flex"
      >
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="comments-container">
            {comments &&
              comments.length > 0 &&
              comments.map((item, index) => {
                return (
                  <div
                    className="review-container gx-mb-2"
                    style={{ width: "100%" }}
                  >
                    <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                      <div className="gx-d-flex">
                        <Avatar size={40} icon={<UserOutlined />} />
                        <div className="gx-d-flex gx-flex-column gx-justify-content-center gx-ml-2 gx-mb-2">
                          <h5 className="gx-black-font  gx-mb-1 text-capitalize">
                            {item.createdByInfo &&
                              item.createdByInfo[0] &&
                              item.createdByInfo[0].firstName +
                                " " +
                                item.createdByInfo &&
                              item.createdByInfo[0] &&
                              item.createdByInfo[0].lastName}
                          </h5>
                          <small className="gx-gray-font">
                            {item &&
                              item.createdAt &&
                              renderDate(item.createdAt)}
                          </small>
                        </div>
                      </div>
                      <div
                        className="gx-pointer"
                        onClick={() => error(index, item)}
                      >
                        ❌
                      </div>
                    </div>
                    <div>{item && item.Description}</div>
                  </div>
                );
              })}
            {comments && comments.length === 0 && <div>No Comments Yet!</div>}
          </div>
        </Col>
      </Row>
    </SimpleModal>
  );
};

export default CommentModal;
