import React from "react";
import SolutionNumber from "./JSONData";
import ReactQuill from "react-quill";
import { Form, Input, Row, Col, Select, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const ExerciseForm = ({ form }) => {
  const handleChange = (data) => {
    form.setFieldsValue({ tipDescription: data });
  };
  const renderEditor = () => {
    return <ReactQuill onChange={(data) => handleChange(data)} />;
  };

  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Exercise Name"
          rules={[
            {
              required: true,
              message: "please enter the exercise name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="suggestedExNo"
          label={"Solution Number"}
          rules={[
            {
              required: true,
              message: "please enter the solution number!",
            },
          ]}
        >
          <Select mode="multiple">
            {SolutionNumber &&
              SolutionNumber.map((item) => (
                <Option value={item.number} key={item.number}>
                  {item.number}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="desc"
          label="Tip Description"
          rules={[
            {
              required: true,
              message: "please enter the tip description!",
            },
          ]}
          initialValue=""
        >
          {renderEditor()}
        </Form.Item>
        <Form.List name="timing">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <>
                  <div className="gx-ml-2 gx-d-flex gx-justify-content-end">
                    <MinusCircleOutlined
                      className="gx-text-red"
                      onClick={() => remove(name)}
                    />
                  </div>
                  <Row className="gx-d-flex gx-flex-row">
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={24}
                      xs={24}
                      className="gx-px-0"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "duration"]}
                        fieldKey={[fieldKey, "duration"]}
                        rules={[
                          { required: true, message: "Missing duration" },
                        ]}
                      >
                        <Input placeholder="Duration of exercise" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[{ required: true, message: "Missing type" }]}
                      >
                        <Input placeholder="Duration of type" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className="gx-pt-0"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default ExerciseForm;
