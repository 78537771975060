import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import CommunityTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditCommunity from "./components/EditComminuty";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import AddCommunity from "./components/AddCommunity";
import ShowModal from "components/Modal/ShowModal";
import { renderStatus } from "utils/commonUseFunction";
import { useSelector } from "react-redux";
import LeaderBoardModel from "./components/LeaderBoardModel";
import HistoryLeaderBoardModel from "./components/HistoryLeaderBoardModel";

const Community = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { communityList } = CurdProps;
  const columns = [
    {
      title: "Community Name",
      render: (record) => <span>{record.communityName || "---"}</span>,
      key: "fullName",
    },
    {
      title: "Total Users",
      render: (record) => (
        <span>{(record && record.users.length) || "---"}</span>
      ),
      key: "users",
    },
    {
      title: "Color",
      render: (record) => (
        <>
          {record.communityColor ? (
            <div
              style={{
                height: "20px",
                width: "30px",
                background: record.communityColor,
              }}
            ></div>
          ) : (
            "----"
          )}
        </>
      ),
      key: "color",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "Status",
    },
    {
      title: "Current Leaderboard",
      render: (record) => (
        <span
          style={{
            cursor: "pointer",
            color: "#3A9DED",
            textDecoration: "underline",
          }}
        >
          <ShowModal
            record={record}
            modalName={"View_Leaderboard_Modal"}
            buttonName="View"
          />
        </span>
      ),
      key: "Status",
    },
    {
      title: "History Leaderboard",
      render: (record) => (
        <span
          style={{
            cursor: "pointer",
            color: "#3A9DED",
            textDecoration: "underline",
          }}
        >
          <ShowModal
            record={record}
            modalName={"View_History_Leaderboard_Modal"}
            buttonName="View"
          />
        </span>
      ),
      key: "Status",
    },
    {
      title: "",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"EditCommunity_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteCommunity"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="communityList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Community?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Community List"
        extra={
          <div className="gx-d-flex">
            {communityList && communityList.length < 1 && <AddCommunity />}
          </div>
        }
      >
        <CommunityTable
          apiName="getCommunity"
          recordName="communityList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <LeaderBoardModel/>
      <EditCommunity />
      <HistoryLeaderBoardModel/>
    </Layout>
  );
};
export default Community;
