import React, { useState } from "react";
import { Form, Row, Col, Tree } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import CategoryForm from "./CategoryForm";
const { DirectoryTree } = Tree;
const AddCategory = () => {
  const categoryProps = useSelector((state) => state.CurdR);
  const { Add_Category_Modal, Loader } = categoryProps;
  const [treeData, setTreeData] = useState([]);
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const setFormFieldData = (parentName, arrayIndex, fieldname, value) => {
    let arrayData = [];

    arrayData = form.getFieldValue(parentName);
    arrayData[arrayIndex][fieldname] = value;
    form.setFieldsValue({ [parentName]: arrayData });
  };

  const checkFormValue = () => {
    const values = form.getFieldsValue();
    values["key"] = values.id ? values.id : 1;
    values["title"] = values.name;
    if (values.children && values.children.length > 0) {
      values.children.forEach((item, index) => {
        let parentKey = item.id;
        item["key"] = parentKey;
        item["title"] = item.name;

        if (item.children) {
          item.children.forEach((Item) => {
            let childKey = Item.id;
            Item["key"] = parentKey + "-" + childKey;
            Item["title"] = Item.name;

            if (Item.children) {
              Item.children.forEach((singleItem) => {
                let grandChildKey = singleItem.id;
                singleItem["key"] =
                  parentKey + "-" + childKey + "-" + grandChildKey;
                singleItem["title"] = singleItem.name;
              });
            }
          });
        }
      });
    }
    setTreeData([values]);
  };

  const renderTreeData = () => {
    if (treeData && treeData.length > 0) {
      return <DirectoryTree treeData={treeData} />;
    } else {
      return <div className="gx-text-center">No Data added yet</div>;
    }
  };

  return (
    <div>
      <FormModal
        addButtonName="Add New"
    
        submitButtonText="Save"
        cancelButtonText="Cancel"
        apiMethod="POST"
        addApiName="addMedicalConditionCategory"
        addApiRequest="AddRecord"
        apiLoader="StartSpinner"
        ModalName="Add_Category_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Add_Category_Modal ? Add_Category_Modal : false}
        width={1000}
        recordName="categoryList"
        form={form}
        extraFieldValue={photoList.imageUrl}
        extraFieldName={"categoryImage"}
      >
        <Row className="gx-flex-row">
          <Col
            xl={14}
            lg={14}
            md={14}
            sm={24}
            xs={24}
            className="gx-px-0"
            style={{ borderRight: "1px solid #C8C8C8" }}
          >
            <div className="model-header">
              <span style={{ color: "#ffffff" }}>New Category</span>
            </div>
            <CategoryForm
              photoList={photoList}
              setPhotoState={setPhotoState}
              form={form}
              checkFormValue={checkFormValue}
              setFormFieldData={setFormFieldData}
            />
          </Col>
          <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gx-px-0">
            <div className="model-header-right">
              <span style={{ color: "#ffffff" }}>Perview</span>
            </div>
            {renderTreeData()}
          </Col>
        </Row>
      </FormModal>
    </div>
  );
};

export default AddCategory;
