import React from "react";
import { Form, Input, Row, Col } from "antd";
import Uploader from "../../../../../components/AvatarUploader/selfImageUpload";
const CategoryForm = ({ photoList, setPhotoState }) => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="name"
          label="Therapy Category Name"
          rules={[
            {
              required: true,
              message: "Therapy Category Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="categoryImage" label="Therapy Icon">
          <Uploader photoList={photoList} setPhotoState={setPhotoState} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CategoryForm;
