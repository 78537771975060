import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";

import ExerciseForm from "./ExerciseForm";

const EditExercise = ({ initialRecord }) => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditExercise_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <FormModal
      modalTitle="Edit Exercise"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editExercise"
      ModalName="EditExercise_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={EditExercise_Modal ? EditExercise_Modal : false}
      initialValues={initialValues}
      width={400}
      form={form}
      recordName="exercisesList"
    >
      <ExerciseForm />
    </FormModal>
  );
};

export default EditExercise;
