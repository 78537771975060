import React from "react";
import SearchBox from "components/Filters/SearchBox";
import SelectFilter from "components/Filters/SelectFilter";
const Index = ({
  options,
  isShowAll,
  recordName,
  apiName,
  searchCustomQuery = {},
  searchPlaceHolder,
  selectPlaceHolder,
  selectItemValue,
  customQuery = {},
  selectLabel,
  selectInputWidth,
  searchInputWidth,
  selectSearchKey,
  setFilterValue,
  useSetFilterValue=false

  
}) => {
  return (
    <div className="gx-d-flex" style={{ gap: "10px" }}>
      <div style={{ width: `${searchInputWidth}px` }}>
        <SearchBox
          apiName={apiName}
          recordName={recordName}
          customQuery={{ ...searchCustomQuery }}
          requestType="FetchRecord"
          placeholder={searchPlaceHolder}
          setFilterValue={setFilterValue}
          useSetFilterValue={useSetFilterValue}
        />
      </div>

      <div style={{ width: `${selectInputWidth}px` }}>
        <SelectFilter
          recordName={recordName}
          isShowingAll={isShowAll}
          className="select-filter"
          apiName={apiName}
          searchKey={selectSearchKey}
          options={options}
          placeholder={selectPlaceHolder}
          customQuery={{ ...customQuery }}
          ItemValue={selectItemValue}
          label={selectLabel}
          setFilterValue={setFilterValue}
          useSetFilterValue={useSetFilterValue}
        />
      </div>
    </div>
  );
};

export default Index;
