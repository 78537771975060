import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import GamificationForm from "./GamificationForm";
const EditGamification = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const [form] = Form.useForm();
  const { Edit_Gamification_Modal, initialValues, Loader } = faqProps;

  return (
    <FormModal
      modalTitle="Edit Gamification"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editPoint"
      ModalName="Edit_Gamification_Modal"
      confirmLoading={Loader}
      recordName="gamificationPointList"
      ModalType="Hide_Modal"
      visible={Edit_Gamification_Modal ? Edit_Gamification_Modal : false}
      initialValues={initialValues}
      width={500}
      form={form}
    >
      <GamificationForm formType='edit' />
    </FormModal>
  );
};

export default EditGamification;
