import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, Button } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import ImageUploader from "../../../../components/AvatarUploader";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Uploader from "../../../../components/AvatarUploader/UploadLocation";
import { useSelector } from "react-redux";
const { Option } = Select;

const FAQForm = ({ photoList, setPhotoState, edit, location, setLocation }) => {
  const pageProps = useSelector((state) => state.CurdR);
  const { allOccupation, conditionListSelect, initialValues } = pageProps;
  console.log("==========conditionListSelect====", initialValues);
  const [SecondaryArray, setSecondaryArray] = useState([]);
  const [PrimaryId, setPrimaryId] = useState("");
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  useEffect(() => {
    if (initialValues) {
      if (initialValues && initialValues.conditionKnowledgeBase) {
        setSecondaryArray(initialValues.conditionKnowledgeBase);
      }
      if (initialValues && initialValues.conditionKnowledgeBasePrimary) {
        setPrimaryId(initialValues.conditionKnowledgeBasePrimary);
      }
    }
  }, []);
  return (
    <div>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="fullName"
                label={<IntlMessages id="specialist.fullName" />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="specialist.fullname_message" />,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="phoneNumber"
                label={<IntlMessages id="specialist.phoneNumber" />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id="specialist.phonenumber_message" />
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            // className="gx-mx-0"
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="email"
                label={<IntlMessages id="specialist.email" />}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: <IntlMessages id="specialist.email_message" />,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="location"
                label={<IntlMessages id="specialist.location" />}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="website"
                label={<IntlMessages id="specialist.website" />}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="highestDegreeLicense"
                label={<IntlMessages id="specialist.highestDegree" />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id="specialist.highestDegree_message" />
                    ),
                  },
                ]}
                initialValue=""
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="association"
                label={<IntlMessages id="specialist.association" />}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="insuranceAccepted"
                label={<IntlMessages id="specialist.insuranceAccepted" />}
                initialValue=""
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <ImageUploader photoList={photoList} setPhotoState={setPhotoState} />
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="gx-px-3"
      >
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="facebook"
            label={<IntlMessages id="specialist.facebook" />}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="twitter"
            label={<IntlMessages id="specialist.twitter" />}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="youtube"
            label={<IntlMessages id="specialist.youtube" />}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className="gx-flex-row gx-mx-3">
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="linkedIn"
            label={<IntlMessages id="specialist.linkedIn" />}
            initialValue=""
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="instagram"
            label={<IntlMessages id="Instagram" />}
            initialValue=""
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="gx-px-3"
      >
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="specialties"
            label={<IntlMessages id="Specialist" />}
          >
            <Select mode="multiple" showSearch>
              {allOccupation &&
                allOccupation.map((item) => {
                  console.log("=======item=====", item);
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.occupationName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className='gx-pt-md-5'>
          <Form.Item
          
            extra={
              <span style={{ color: "#9b9b9b" }}>
                *This means that you have treated at least one patient/client
                with this condition.
              </span>
            }
            name="conditionKnowledgeBasePrimary"
            label={"Condition Knowledge Base (Primary)"}
            rules={[
              {
                required: true,
                message: "Condition Knowledge Base Primary required!",
              },
            ]}
          >
            <Select onChange={(e) => setPrimaryId(e)}>
              {conditionListSelect &&
                conditionListSelect.map((item) => {
                  if (!SecondaryArray.includes(item._id)) {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.conditionName}
                      </Option>
                    );
                  }
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} >
          <Form.Item
            extra={
              <span style={{ color: "#9b9b9b" }}>
                *This means that you have treated at least one patient/client
                with this condition.
              </span>
            }
            name="conditionKnowledgeBase"
            label={"Condition Knowledge Base (Secondary)"}
            rules={[
              {
                required: true,
                message: "Condition Knowledge Base Secondary required!",
              },
            ]}
          >
            <Select
              onChange={(e) => setSecondaryArray(e)}
              mode="multiple"
              showSearch
            >
              {conditionListSelect &&
                conditionListSelect.map((item) => {
                  if (PrimaryId !== item._id) {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.conditionName}
                      </Option>
                    );
                  }
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="education" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Education" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input multiple style={{ width: "60%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button "
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                  >
                    Add Education
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="experience" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Experience" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input multiple style={{ width: "60%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button "
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                  >
                    Add Experience
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="language" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Language" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input multiple style={{ width: "60%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button "
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                  >
                    Add language
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Uploader location={location} setLocation={setLocation} />
        </Col>
      </Row>
    </div>
  );
};

export default FAQForm;
