import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import AccountExerciseForm from "./AccountExerciseForm";

const AddAccountExercise = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Account_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Exercise"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addAccountExercise"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Account_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Account_Modal ? Add_Account_Modal : false}
      width={550}
      form={form}
      recordName="accountExList"
    >
      <AccountExerciseForm />
    </FormModal>
  );
};

export default AddAccountExercise;
