import React, { useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import CategoryForm from "./CategoryForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
const EditCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditCategory_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  const inImage = initialValues && initialValues.categoryImage;

  let initialState = {
    loading: false,
    imageUrl: inImage ? inImage : "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  return (
    <FormModal
      modalTitle="Edit Category"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editCategory"
      ModalName="EditCategory_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={EditCategory_Modal ? EditCategory_Modal : false}
      initialValues={initialValues}
      width={800}
      recordName="categoryList"
      form={form}
      extraFieldValue={photoList.imageUrl}
      extraFieldName={"categoryImage"}
    >
      <CategoryForm photoList={photoList} setPhotoState={setPhotoState} />
    </FormModal>
  );
};

export default EditCategory;
