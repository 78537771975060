import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import EducationForm from "./EducationForm";

const AddNewQuestion = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { AddEducation_Modal, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Topic"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addEducation"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="AddEducation_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={AddEducation_Modal ? AddEducation_Modal : false}
      width={700}
      recordName="eductionList"
      form={form}
    >
      <EducationForm />
    </FormModal>
  );
};

export default AddNewQuestion;
