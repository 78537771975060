import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import CategoryForm from "./CategoryForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
const EditCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_SelfCategory_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  useEffect(() => {
    if (initialValues && initialValues.categoryImage) {
      setPhotoState({ imageUrl: initialValues.categoryImage });
    }
  }, [initialValues]);

  return (
    <FormModal
      modalTitle="Edit Category"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editSelfCopingCategory"
      ModalName="Edit_SelfCategory_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_SelfCategory_Modal ? Edit_SelfCategory_Modal : false}
      initialValues={initialValues}
      width={500}
      recordName="selfCategoryList"
      form={form}
      extraFieldValue={photoList.imageUrl}
      extraFieldName={"categoryImage"}
    >
      <CategoryForm photoList={photoList} setPhotoState={setPhotoState} />
    </FormModal>
  );
};

export default EditCategory;
