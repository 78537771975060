import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import OccupationForm from "./OccupationForm";

const AddNewOccupation = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Add_Occupation_Modal, Loader } = curdProps;

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Occupation"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addOccupation"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Occupation_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Occupation_Modal ? Add_Occupation_Modal : false}
      width={550}
      recordName="occupationList"
      form={form}
    >
      <OccupationForm />
    </FormModal>
  );
};

export default AddNewOccupation;
