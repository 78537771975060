import React, { useEffect } from "react";
import SimpleModal from "../../../../../components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/Table/List";
import { DataGetAction } from "../../../../../redux/actions/CommonHttp";
import ShowModal from "../../../../../components/Modal/ShowModal";
import LeaderBoardModelGraph from "./LeaderBoardModelGraph";
import moment from "moment";
import { Image } from "antd";
const HistoryLeaderBoardModel = () => {
  const dispatch = useDispatch();
  const {
    View_History_Leaderboard_Modal,
    initialValues,
    leaderBoardListHistory,
  } = useSelector((state) => state.CurdR);

  let columns = [
    {
      title: "Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MMM-YYYY")}</span>
      ),
      key: "date",
    },
    {
      title: "Name",
      render: (record) => <span>{record.name}</span>,
      key: "Name",
    },
    {
      title: "Point",
      render: (record) => <span>{record.totalPoint}</span>,
      key: "Point",
    },
    {
      title: "Medal",
      render: (record) => <span>{record.title}</span>,
      key: "Madel",
    },
    {
      title: "Reward",
      render: (record) => (
        <span>
          <Image src={record.madel} style={{ width: "50px", height: "50px" }} />
        </span>
      ),
      key: "reward",
    },
  ];

  useEffect(() => {
    dispatch(
      DataGetAction(
        "getLeaderBoardHistory",
        "FetchRecord",
        { query: "all", _id: initialValues && initialValues._id },
        "",
        "",
        "leaderBoardListHistory"
      )
    );
  }, [View_History_Leaderboard_Modal]);

  return (
    <>
      <SimpleModal
        visible={View_History_Leaderboard_Modal}
        title="View History Leaderboard"
        modalFor="View_History_Leaderboard_Modal"
        closable={true}
      >
        <Table
          callApi={false}
          columns={columns}
          tempData={leaderBoardListHistory}
        />
      </SimpleModal>
      <LeaderBoardModelGraph />
    </>
  );
};

export default HistoryLeaderBoardModel;
