import React from "react";
import { Col, Form, Row, Input } from "antd";

const CharacterOfPainForm = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="characterOfPainName"
          label={"Character Of Pain Name"}
          rules={[
            {
              required: true,
              message: "please enter the character of pain name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CharacterOfPainForm;
