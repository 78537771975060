import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, InputNumber, Checkbox } from "antd";
import ReactQuill from "react-quill";
import Uploader from "components/AvatarUploader/questionImage";
const { Option } = Select;

const SymptomForm = ({ form, photoList, setPhotoState, initialValues }) => {
  const handleChange = (data) => {
    form.setFieldsValue({ description: data });
  };
  const [hide, setHind] = useState("");
  const renderGermanEditor = () => {
    return <ReactQuill onChange={(data) => handleChange(data)} />;
  };
  const handleChanger = (e) => {
    setHind(e);
  };


  const setInitialType = () => {
    if (initialValues && initialValues.type) {
      setHind(initialValues.type);
    }
  };
  useEffect(setInitialType, []);

  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="type" rules={[{ required: true }]}>
            <Select
              onChange={(e) => {
                handleChanger(e);
              }}
              placeholder="Select a option and change input text above"
            >
              <Option value="drawer">Drawer</Option>
              <Option value="community">Community</Option>
              <Option value="forSignup">For Signup</Option>
              <Option value="forSignupPrivacyPolicy">
                Signup Privacy Policy
              </Option>
            </Select>
          </Form.Item>
        </Col>
        {hide === "community" && (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="showCommunity" valuePropName="checked">
              <Checkbox label="Go To You Community">
                On Click Show Community
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {hide === "drawer" && (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="showTrends" valuePropName="checked">
              <Checkbox>On Click Show Trend</Checkbox>
            </Form.Item>
            <Form.Item name="showDailySummery" valuePropName="checked">
              <Checkbox>On Click Show Daily Summery</Checkbox>
            </Form.Item>
            <Form.Item name="showPMP" valuePropName="checked">
              <Checkbox>On Click Show PMP</Checkbox>
            </Form.Item>

            <Form.Item name="showPainColorCode" valuePropName="checked">
              <Checkbox>On Click Show Pain color code</Checkbox>
            </Form.Item>
          </Col>
        )}

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="orderNumber"
            label="Order Number"
            rules={[
              {
                required: false,
                message: "Enter the order number",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="displayName"
            label="Display Name"
            rules={[
              {
                required: true,
                message: "Enter the display Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="displayName" label="Display Image">
            <Uploader photoList={photoList} setPhotoState={setPhotoState} />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Enter the description",
              },
            ]}
            initialValue=""
          >
            {renderGermanEditor()}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default SymptomForm;
