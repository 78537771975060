import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import CategoryForm from "./CategoryForm";

const AddNewQuestion = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditCategory_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <FormModal
      modalTitle="Edit Category"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editExerciseCategory"
      ModalName="EditCategory_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={EditCategory_Modal ? EditCategory_Modal : false}
      initialValues={initialValues}
      width={400}
      form={form}
      recordName="exerciseCategoryList"
    >
      <CategoryForm />
    </FormModal>
  );
};

export default AddNewQuestion;
