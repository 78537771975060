import React from "react";
import { Form, Input, Row, Col } from "antd";
import ReactQuill from "react-quill";

const TypeForm = ({ form }) => {
  const handleChange = (data) => {
    form.setFieldsValue({ description: data });
  };

  const renderGermanEditor = () => {
    return <ReactQuill onChange={(data) => handleChange(data)} />;
  };

  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="triggerTypeName"
            label={"Trigger Type Name"}
            rules={[
              {
                required: true,
                message: "please enter the character of trigger type name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="triggerSubtitle"
            label={"Trigger Subtitle"}
            rules={[
              {
                required: true,
                message: "please enter the trigger Subtitle name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="triggerTypeInfoText"
            label={"Trigger Type InfoText"}
            rules={[
              {
                required: true,
                message: "please enter the character!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="triggerTypeDesc"
            label={"Trigger Type Desc"}
            rules={[
              {
                required: true,
                message: "please enter trigger type description!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="triggerGraphDesc"
            label={"Trigger Graph Desc"}
            rules={[
              {
                required: true,
                message: "please enter Trigger Graph Desc",
              },
            ]}
            initialValue=""
          >
            {renderGermanEditor()}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default TypeForm;
