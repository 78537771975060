import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import PhysicianForm from "./PhysicianForm";
const EditPhysician = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_Physician_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <FormModal
      modalTitle="Edit Physician"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editPainManagementSpecialist"
      ModalName="Edit_Physician_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Physician_Modal ? Edit_Physician_Modal : false}
      initialValues={initialValues}
      width={550}
      form={form}
      recordName="physicianList"
    >
      <PhysicianForm />
    </FormModal>
  );
};

export default EditPhysician;
