import React from "react";
import { Form, Input, Row, Col } from "antd";

const VitaminForm = () => {
  return (
    <div>
      <Row className="gx-d-flex gx-flex-row gx-align-items-center">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="vitaminName"
            label="Vitamin Name"
            rules={[
              {
                required: true,
                message: "Pleas enter the Vitamin name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default VitaminForm;
