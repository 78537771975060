import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import TipForm from "./TipForm";

const AddTip = () => {
  const CurdRProps = useSelector((state) => state.CurdR);
  const { Add_Tips_Modal, Loader } = CurdRProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Tip"
  
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addTip"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Tips_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Tips_Modal ? Add_Tips_Modal : false}
      width={550}
      form={form}
      recordName="tipsList"
    >
      <TipForm />
    </FormModal>
  );
};

export default AddTip;
