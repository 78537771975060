import React from "react";
import { Form, Input, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;
const ExerciseForm = () => {
  const pageProps = useSelector((state) => state.CurdR);
  const { allCategory } = pageProps;
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="categoryId"
          label="Category Name"
          rules={[
            {
              required: true,
              message: "please select the category!",
            },
          ]}
        >
          <Select>
            {allCategory &&
              allCategory.map((item) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.categoryName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="exerciseName"
          label="Exercise Name"
          rules={[
            {
              required: true,
              message: "please enter the exercise name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ExerciseForm;
