import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import DrugCategoryForm from "./DrugCategoryForm";
const EditDrugCategory = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_DrugCategory_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Drug Category"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editDrugCategory"
        ModalName="Edit_DrugCategory_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_DrugCategory_Modal ? Edit_DrugCategory_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="drugCategoryList"
      >
        <DrugCategoryForm />
      </FormModal>
    </div>
  );
};

export default EditDrugCategory;
