import React, { useEffect } from "react";
import Widget from "components/Widget";
import EducationList from "components/Table/List";
import Layout from "components/Layout";
import Filters from "components/Filters/SearchAndSelectFilter";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditEducation from "./components/EditEducation";
import ArticleModal from "./components/ArticleModal";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddEducation from "./components/AddEducation";
import { DataGetAction } from "redux/actions/CommonHttp";
import ShowModal from "components/Modal/ShowModal";
import { useDispatch, useSelector } from "react-redux";
import { renderArticle, renderMoreHtmlDec } from "utils/commonUseFunction";
const Education = () => {
  const dispatch = useDispatch();
  const CurdProps = useSelector((state) => state.CurdR);
  const { categoriesForFilter } = CurdProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getEducationCategory",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "categoriesForFilter"
      )
    );
  };
  useEffect(fetchList, []);
  const renderSubcategories = (categories) => {
    return (
      categories &&
      categories.length &&
      categories.map((item) => {
        return item + "/";
      })
    );
  };

  const columns = [
    {
      title: "NO.",
      render: (record) => <span>{record.educationNo}</span>,
      key: "no",
    },
    {
      title: "Category",
      render: (record) => (
        <span>
          {record.category && record.category.length && record.category[0]}
        </span>
      ),
      key: "category",
    },
    {
      title: "Sub Category",
      render: (record) => (
        <span>
          {renderSubcategories(
            record.category && record.category.length && record.category
          )}
        </span>
      ),
      key: "subCategory",
      width:"300px"
    },
    {
      title: "Article",
      render: (record) => {
        return (
          <div>
            <span>{renderArticle(record.article)}</span>
            <span>
              {renderMoreHtmlDec(
                record.article,
                "Open_Article_Modal",
                dispatch
              )}
            </span>
          </div>
        );
      },
      key: "article",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"EditEducation_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteEducation"
          method="DELETE"
          selectedItem={record}
          recordName="eductionList"
          apiRequest="DeleteRecord"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Education?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Topic"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getEducation"
              recordName="eductionList"
              isShowAll={true}
              searchPlaceHolder="Search by Keyword in Article"
              selectLabel="name"
              selectItemValue="name"
              searchCustomQuery={{ query: "search", key: "keywordSearch" }}
              selectPlaceHolder="Search by category"
              options={categoriesForFilter}
              selectSearchKey="catId"
              selectInputWidth={150}
              searchInputWidth={230}
            />

            <AddEducation />
          </div>
        }
      >
        <EducationList
          apiName="getEducation"
          recordName="eductionList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditEducation />
      <ArticleModal />
    </Layout>
  );
};

export default Education;
