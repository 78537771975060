import React from "react";
import { Select } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
const CallFilters = ({
  showAll,
  placeholder,
  marginRight,
  options,
  ItemValue,
  searchId,
  width = "160px",
  label,
  apiName,
  searchKey,
  isShowingAll,
  className,
  defaultValue,
  recordName,
  customQuery = {},
  setFilterValue,
  useSetFilterValue = false,
}) => {
  const dispatch = useDispatch();
  const handleChange = (data = "all") => {
    const query = { query: "search", key: searchKey, value: data };
    if (useSetFilterValue) {
      setFilterValue(query);
    }
    if (data === "all") {
      query.query = "all";
      if (showAll) {
        return showAll();
      }
    }
    dispatch(
      DataGetAction(
        apiName,
        "FetchRecord",
        { ...query, ...customQuery },
        "searching",
        "",
        recordName
      )
    );
  };

  return (
    <Select
      allowClear
      showSearch
      optionFilterProp="children"
      style={{ width: width }}
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
      defaultValue={defaultValue}
      className={className}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
    >
      {isShowingAll && <Select.Option value="all">All</Select.Option>}
      {options &&
        options.map((Item) => {
          return (
            <Select.Option value={Item[`${ItemValue}`]}>
              {Item[`${label}`]}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default CallFilters;
