import React from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const ImageUploader = ({ name, setPhotoState, photoList, initialValues }) => {
  const token = localStorage.getItem("token");

  const { loading, imageUrl } = photoList;

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setPhotoState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      setPhotoState({
        imageUrl: info.file.response.url,
        loading: false,
      });
    }
  };

  const uploadButton = (
    <div className="gx-w-100 self-coping">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div>
          {" "}
          <span
            className="icon icon-image gx-d-block gx-w-100"
            style={{ fontSize: "33px", opacity: "0.3" }}
          ></span>
          <span>upload picture</span>
        </div>
      )}
    </div>
  );

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { file: name },
    onChange: handleChange,
    multiple: false,
    listType: "picture-card",
    className: "gx-w-100 self-coping",
    showUploadList: false,
  };

  return (
    <div>
      <Upload name={name} {...ImageProps} className="self-coping gx-w-100">
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "6px",
              objectFit: "cover",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default ImageUploader;
