import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import OverTheCounterForm from "./OverTheCounterForm";
const EditOverTheCounter = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_OverTheCounter_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Over The Counter"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editOTC"
        ModalName="Edit_OverTheCounter_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_OverTheCounter_Modal ? Edit_OverTheCounter_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="overTheCounterList"
      >
        <OverTheCounterForm />
      </FormModal>
    </div>
  );
};

export default EditOverTheCounter;
