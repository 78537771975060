import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
const DescriptionModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { Open_Description_Modal, initialValues } = CurdProps || {};

  return (
    <>
      <SimpleModal
        visible={Open_Description_Modal ? Open_Description_Modal : false}
        modalFor="Open_Description_Modal"
        width={500}
        title={"Description"}
      >
        {Open_Description_Modal &&
          initialValues.map((item) => (
            <ul>
              <li className='gx-text-capitalize'>{item}</li>
            </ul>
          ))}
      </SimpleModal>
    </>
  );
};

export default DescriptionModal;
