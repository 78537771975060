import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import ConservativeCareForm from "./ConservativeCareForm";

const AddConservativeCare = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_ConservativeCare_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <div>
      <FormModal
        addButtonName="Add New"
        modalTitle="Add CAIT SPECIALIST"
        submitButtonText="Save"
        cancelButtonText="Cancel"
        apiMethod="POST"
        addApiName="addConservativeCare"
        addApiRequest="AddRecord"
        apiLoader="StartSpinner"
        ModalName="Add_ConservativeCare_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={
          Add_ConservativeCare_Modal ? Add_ConservativeCare_Modal : false
        }
        width={550}
        form={form}
        className="doctor-profile-modal"
        recordName="conservativeCareList"
      >
        <ConservativeCareForm />
      </FormModal>
    </div>
  );
};

export default AddConservativeCare;
