import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import TypeTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditQuestion from "./components/EditQuestion";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddQuestion from "./components/AddQuestion";
import ShowModal from "components/Modal/ShowModal";
import SearchBox from "components/Filters/SearchBox";
import { useDispatch } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getTriggerTypes",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allTriggerTypes"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Trigger Type",
      render: (record) => (
        <span>{record.triggerType && record.triggerType}</span>
      ),
      key: "triggerType",
      width: "300px",
    },
    {
      title: "Trigger Question",
      render: (record) => <span>{record.triggerQuestionName}</span>,
      key: "triggerQuestionName",
    },

    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Question_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteTriggerQuestion"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="questionList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Question?"
          content=""
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="TRIGGER QUESTIONS"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getTriggerQuestions"
              recordName="questionList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Type/Question"
              width="200px"
            />
            <AddQuestion />
          </div>
        }
      >
        <TypeTable
          apiName="getTriggerQuestions"
          recordName="questionList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditQuestion />
    </Layout>
  );
};
export default Index;
