import React from "react";
import { Form, Input, Button, Space, Row, Col } from "antd";
import Uploader from "../../../../../components/AvatarUploader/ImageUploader";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const CategoryForm = ({ photoList, setPhotoState }) => {
  const renderSubChildCategory = (name, parentName) => {
    return (
      <Col xl={24} lg={24} md={24}>
        <Form.List name={[name, "children"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row>
                  <Col xl={24} lg={24} md={24}>
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "_id"]}
                        fieldKey={[fieldKey, "_id"]}
                        initialValue={key}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label={`${parentName}_${key + 1}`}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        style={{
                          width: "200px",
                        }}
                      >
                        <Input placeholder="Child Category Name" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  </Col>
                  {renderSubChildCategory(name, `${parentName}_${key + 1}`)}
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className="gx-pt-0"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {`Add Child  ${parentName}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    );
  };

  const renderChildCategory = (name, parentName) => {
    return (
      <Row>
        <Col xl={24} lg={24} md={24}>
          <Form.List name={[name, "children"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    <Row>
                      <Col xl={24} lg={24} md={24}>
                        <div className="gx-d-flex gx-flex-row">
                          <div className="gx-mr-2">
                            <Form.Item
                              {...restField}
                              name={[name, "_id"]}
                              fieldKey={[fieldKey, "_id"]}
                              initialValue={key}
                              className="gx-d-none"
                            >
                              <Input disabled />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              label={`${parentName}_${key + 1}`}
                              name={[name, "name"]}
                              fieldKey={[fieldKey, "name"]}
                              style={{
                                width: "200px",
                              }}
                            >
                              <Input placeholder="Child Category Name" />
                            </Form.Item>
                          </div>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </Col>
                      {renderSubChildCategory(name, `${parentName}_${key + 1}`)}
                    </Row>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-pt-0"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {`Add Child ${parentName}`}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    );
  };

  const renderCategoryChild = (parentName) => {
    return (
      <Form.List name="children">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => {
              console.log("===== name ====", fields, name);
              return (
                <div key={key}>
                  <Row className="gx-flex-row gx-ml-0">
                    <Col
                      className="gx-ml-0 gx-mr-0 gx-p-0"
                      xl={18}
                      lg={18}
                      md={18}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "_id"]}
                        label={key}
                        fieldKey={[fieldKey, "_id"]}
                        initialValue={key}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "categoryImage"]}
                        fieldKey={[fieldKey, "categoryImage"]}
                        className="gx-d-none"
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={`${parentName}_${key + 1}`}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Subcategory name",
                          },
                        ]}
                      >
                        <Input placeholder="Subcategory Name" />
                      </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} md={6}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  {renderChildCategory(name, `${parentName}_${key + 1}`)}
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                className="gx-pt-0"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Subcategory
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="name"
            label="Category Name"
            rules={[
              {
                required: true,
                message: "Category name is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="categoryImage" label="Category Image">
            <Uploader photoList={photoList} setPhotoState={setPhotoState} />
          </Form.Item>
        </Col>
        <Col
          className="category-columns"
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ overflow: "auto" }}
        >
          {renderCategoryChild("Category")}
        </Col>
      </Row>
    </div>
  );
};

export default CategoryForm;
