import React from "react";
import { Col, Form, Row, Input } from "antd";

const PhysicianForm = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="painSpecialistName"
          label="Physician (MD) Type"
          rules={[
            {
              required: true,
              message: "please enter pain specialist name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PhysicianForm;
