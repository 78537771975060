import React from "react";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import UserForm from "./UserForm";
import { useSelector } from "react-redux";

const EditUser = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Edit_User_Modal, Loader, initialValues } = curdProps;

  return (
    <FormModal
      modalTitle="Edit User"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editUser"
      ModalName="Edit_User_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_User_Modal ? Edit_User_Modal : false}
      buttonStyleClass="gx-buttonStyleClass"
      isShowButton={false}
      initialValues={initialValues}
      width={520}
      form={form}
      recordName="userList"
    >
      <UserForm />
    </FormModal>
  );
};

export default EditUser;
