import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
const SocialModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { social_media_Modal, initialValues } = CurdProps || {};

  const renderRow = (label, value) => {
    return (
      <div class="gx-d-flex gx-justify-content-between gx-mb-2">
        <span style={{ width: "25%" }}>{label}</span>
        {value ? (
          <a style={{ width: "75%" }} href={value}>
            {value}
          </a>
        ) : (
          "---"
        )}
      </div>
    );
  };

  return (
    <>
      <SimpleModal
        visible={social_media_Modal ? social_media_Modal : false}
        modalFor="social_media_Modal"
        width={500}
        title={"Social Media"}
      >
        {renderRow("Facebook", initialValues && initialValues.facebook)}
        {renderRow("Twitter", initialValues && initialValues.twitter)}
        {renderRow("Youtube", initialValues && initialValues.youtube)}
        {renderRow("Linkedin", initialValues && initialValues.linkedIn)}
      </SimpleModal>
    </>
  );
};

export default SocialModal;
