import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import TypeForm from "./TypeForm";

const AddType = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Type_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Type"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addTriggerType"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Type_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Type_Modal ? Add_Type_Modal : false}
      width={550}
      form={form}
      recordName="typeList"
    >
      <TypeForm form={form} />
    </FormModal>
  );
};

export default AddType;
