import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";

const ArticleModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { Open_Article_Modal, initialValues } = CurdProps || {};
  return (
    <>
      <SimpleModal
        visible={Open_Article_Modal ? Open_Article_Modal : false}
        modalFor="Open_Article_Modal"
        width={500}
        title={"Article"}
      >
        <p
          className="dangerous-text"
          dangerouslySetInnerHTML={{ __html: initialValues && initialValues }}
        ></p>
      </SimpleModal>
    </>
  );
};

export default ArticleModal;
