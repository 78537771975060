import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import CharacterOfPainForm from "./CharacterOfPainForm";

const AddCharacterOfPain = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_CharacterOfPain_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Character of Pain"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addCharacterOfPain"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_CharacterOfPain_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_CharacterOfPain_Modal ? Add_CharacterOfPain_Modal : false}
      width={550}
      form={form}
      recordName="characterOfPainList"
    >
      <CharacterOfPainForm />
    </FormModal>
  );
};

export default AddCharacterOfPain;
