import React from "react";
import { Form, Input, Row, Col } from "antd";

const userForm = () => {
  return (
    <div>
      <Row className="gx-d-flex gx-flex-row gx-align-items-center">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "First Name required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Last Name required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default userForm;
