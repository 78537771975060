import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import NotificationForm from "./PackageForm";
const EditPackage = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_Package_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Package"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editPackage"
        ModalName="Edit_Package_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_Package_Modal ? Edit_Package_Modal : false}
        initialValues={initialValues}
        width={500}
        form={form}
        recordName="packageList"
      >
        <NotificationForm />
      </FormModal>
    </div>
  );
};

export default EditPackage;
