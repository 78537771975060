import React, { useEffect } from "react";
import SimpleModal from "../../../../../components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/Table/List";
import { DataGetAction } from "../../../../../redux/actions/CommonHttp";
import ShowModal from "../../../../../components/Modal/ShowModal";
import LeaderBoardModelGraph from "./LeaderBoardModelGraph";
const LeaderBoardModel = () => {
  const dispatch = useDispatch();
  const { View_Leaderboard_Modal, initialValues, leaderBoardList } =
    useSelector((state) => state.CurdR);

  let columns = [
    {
      title: "Name",
      render: (record) => <span>{record.name}</span>,
      key: "Name",
    },
    {
      title: "Point",
      render: (record) => <span>{record.totalPoint}</span>,
      key: "Point",
    },
    {
      title: "Medal",
      render: (record) => <span>{record.title}</span>,
      key: "Madel",
    },
    {
      title: "Graph",
      render: (record) => (
        <span
          style={{
            cursor: "pointer",
            color: "#3A9DED",
            textDecoration: "underline",
          }}
        >
          <ShowModal
            record={record}
            modalName={"View_Leaderboard_Graph_Modal"}
            buttonName="View"
          />
        </span>
      ),
      key: "Status",
    },
  ];

  useEffect(() => {
    dispatch(
      DataGetAction(
        "getLeaderBoard",
        "FetchRecord",
        { query: "all", _id: initialValues && initialValues._id },
        "",
        "",
        "leaderBoardList"
      )
    );
  }, [View_Leaderboard_Modal]);

  return (
    <>
      <SimpleModal
        visible={View_Leaderboard_Modal}
        title="View Leaderboard"
        modalFor="View_Leaderboard_Modal"
        closable={true}
      >
        <Table callApi={false} columns={columns} tempData={leaderBoardList} />
      </SimpleModal>
      <LeaderBoardModelGraph />
    </>
  );
};

export default LeaderBoardModel;
