import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AlternateTherapyTable from "components/Table/List";
import EditPackage from "./components/EditPackage";
import TableAction from "components/Table/Actions/ActionDropdown";
import ShowModal from "components/Modal/ShowModal";
import { useSelector } from "react-redux";
import AddPackage from "./components/AddPackage";
import DescriptionModal from "./components/DescriptionMoadl";

const Index = () => {
  const notificationProps = useSelector((state) => state.CurdR);
  const { packageList } = notificationProps;
  const columns = [
    {
      title: "Name",
      render: (record) => <span>Premium</span>,
      key: "notification",
      width: "200px",
    },
    {
      title: "Price",
      render: (record) => (
        <span>$ {record && record.price ? record.price.toFixed(2) : ""}</span>
      ),
      key: "notification",
      width: "200px",
    },
    {
      title: "Description",
      render: (record) => (
        <div className='gx-pointer gx-text-primary'>
          {record && record.description && record.description.length > 0 ? (
            <ShowModal
              record={record.description}
              modalName={"Open_Description_Modal"}
              buttonName="View"
            />
          ) : (
            " ----- "
          )}
        </div>
      ),
      key: "notification",
    },

    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column gx-p-2">
        <ShowModal
          record={record}
          modalName={"Edit_Package_Modal"}
          buttonName="Edit"
        />
      </div>
    );
  };

  const notificationLength =
    packageList && packageList.length ? packageList.length : 0;

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Package"
        extra={notificationLength === 0 && <AddPackage />}
      >
        <AlternateTherapyTable
          apiName="getPackageDetail"
          recordName="packageList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditPackage />
      <DescriptionModal />
    </Layout>
  );
};
export default Index;
