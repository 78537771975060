import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import NatureOfVisitForm from "./NatureOfVisitForm";
const EditPhysician = () => {
  const CurdRProps = useSelector((state) => state.CurdR);
  const { Edit_NatureOfVisit_Modal, initialValues, Loader } = CurdRProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Nature of visit"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editNatureOfVisit"
        ModalName="Edit_NatureOfVisit_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_NatureOfVisit_Modal ? Edit_NatureOfVisit_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="natureOfVisitList"
      >
        <NatureOfVisitForm />
      </FormModal>
    </div>
  );
};

export default EditPhysician;
