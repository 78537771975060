import React, { useEffect, useState } from "react";
import Widget from "components/Widget";
import EducationList from "../../../../components/Table/PageNationTable";
import Layout from "components/Layout";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditArticle from "./components/EditArticle";
import ArticleModal from "./components/ArticleModal";
import FilterOnPress from "../../../../components/Filters/FilterOnPress";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddArticle from "./components/AddArticle";
import ShowModal from "components/Modal/ShowModal";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import { renderArticle, renderMoreHtmlDec } from "utils/commonUseFunction";
const Education = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});
  const CurdProps = useSelector((state) => state.CurdR);
  const { categoriesForFilter, conditionList } = CurdProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getMedicalConditionCategory",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "categoriesForFilter"
      )
    );
    dispatch(
      DataGetAction(
        "getMedicalConditionCategory",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "allCategoriesList"
      )
    );
    dispatch(
      DataGetAction(
        "getConditions",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "conditionList"
      )
    );
  };
  useEffect(fetchList, []);

  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.educationNo}</span>,
      key: "no",
      width: "50px",
    },
    {
      title: "Condition Name",
      render: (record) => <span>{record.conditionName}</span>,
      key: "no",
      width: "150px",
    },

    {
      title: "Category Name",
      render: (record) => <span>{record.name}</span>,
      key: "category",
      width: "150px",
    },

    {
      title: "Article",
      render: (record) => {
        return (
          <div>
            <span>{renderArticle(record && record.article, 100, 65)} </span>
            <span>
              {renderMoreHtmlDec(
                record.article,
                "Open_Article_Modal",
                dispatch
              )}
            </span>
          </div>
        );
      },
      key: "article",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Article_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteMedicalArticle"
          method="DELETE"
          selectedItem={record}
          recordName="articleList"
          apiRequest="DeleteRecordPagination"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Article?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="ARTICLE"
        extra={
          <div className="gx-d-flex" style={{ gap: "10px" }}>
            <FilterOnPress
              apiName="getMedicalArticle"
              recordName="articleList"
              selectPlaceHolder="Category Name"
              searchPlaceHolder="Article"
              ItemValue="_id"
              secondLabel="conditionName"
              secondItem="_id"
              secondOptions={conditionList}
              options={categoriesForFilter}
              searchKey="conditionArticle"
              label="name"
              isShowAll={true}
              setFilterValue={setFilterValue}
              useSetFilterValue={true}
              customQuery={{ pageNumber: 1, limit: 10 }}
            />

            <AddArticle />
          </div>
        }
      >
        <EducationList
          apiName="getMedicalArticle"
          recordName="articleList"
          scroll={true}
          pagination={true}
          columns={columns}
          filterValue={filterValue}
        />
      </Widget>
      <EditArticle />
      <ArticleModal />
    </Layout>
  );
};

export default Education;
