import React, { useEffect } from "react";
import Widget from "components/Widget";
import SpecialistTable from "components/Table/List";
import Filters from "components/Filters/SearchAndSelectFilter";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditInvite from "./components/EditInvite";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddSpecialist from "./components/AddSpecialist";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { renderStatus } from "utils/commonUseFunction";
import KnowledgeBaseModal from "./components/KnowledgeBaseModal";
import SocialMediaModal from "./components/SocialMediaModal";
import SpecialtyBaseModal from "./components/SpecialtyModal";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import EditSpecialist from "./components/EditSpecialist";
import ResetPasswordModal from "./components/ResetPassword";
const Index = () => {
  const dispatch = useDispatch();
  const faqProps = useSelector((state) => state.CurdR);
  const { initialValues } = faqProps;
  console.log("========== initialValues ====", initialValues);
  const profile = initialValues && initialValues.profileImageUrl !== null;
  const location = initialValues && initialValues.locationImage !== null;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getOccupations",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allOccupation"
      )
    );
    dispatch(
      DataGetAction(
        "getConditions",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "conditionListSelect"
      )
    );
    dispatch(
      DataGetAction("getSPProfile", "FetchRecord", { query: "all" }, "")
    );
  };

  useEffect(fetchList, []);
  const reinviteStatus = (data) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "reinviteSpecialist",
        "EditRecord",
        { _id: data._id },
        "",
        "",
        "specialistList"
      )
    );
  };
  const updateUserStatus = (data, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateSpecialist",
        "EditRecord",
        { _id: data._id, status: status },
        "",
        "",
        "specialistList"
      )
    );
  };

  const renderRestore = (record) => {
    if (record.status === "deleted" && record.phoneNumber) {
      return (
        <span
          className="action-link gx-pointer"
          onClick={() => updateUserStatus(record, "active")}
        >
          Restore
        </span>
      );
    } else if (record.status === "deleted") {
      return (
        <span
          className="action-link gx-pointer"
          onClick={() => updateUserStatus(record, "reinvite")}
        >
          Restore
        </span>
      );
    }
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status === "active" && (
          <>
            <span
              className="action-link gx-pointer"
              onClick={() => updateUserStatus(record, "block")}
            >
              Block
            </span>
            <ShowModal
              record={record}
              modalName={"EditSpecialist_Modal"}
              buttonName="Edit"
            />
          </>
        )}
        {(record.status === "block" || record.status === "invite") && (
          <span
            className="action-link gx-pointer"
            onClick={() => updateUserStatus(record, "active")}
          >
            Active
          </span>
        )}
        {renderRestore(record)}

        {record.status === "invite" && (
          <span
            className="action-link gx-pointer"
            onClick={() => reinviteStatus(record, "reinvite")}
          >
            Reinvite
          </span>
        )}
        {record.status === "active" && (
          <ShowModal
            modalName={"Reset_Specialist_Password_Modal"}
            buttonName="Reset Password"
            record={record}
          />
        )}
        {record.status !== "deleted" && (
          <DeleteConfirm
            type="deleteSpecialist"
            method="DELETE"
            selectedItem={record}
            apiRequest="DeleteRecord"
            recordName="specialistList"
            buttonName="Yes"
            oKText="Delete"
            title="Are you sure you want to delete this specialist?"
            content=""
            Icon="Delete"
          />
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      render: (record) => (
        <span>{record.specialistId ? record.specialistId : "----"}</span>
      ),
      key: "id",
      width: 30,
    },
    {
      title: "FullName",
      render: (record) => (
        <span>{record && record.fullName ? record.fullName : "----"}</span>
      ),
      key: "fullName",
      width: 200,
    },
    {
      title: "Email",
      render: (record) => (
        <span>{record && record.email ? record.email : "----"}</span>
      ),
      key: "Email",
      width: 200,
    },
    {
      title: "Phone No",
      render: (record) => (
        <span>
          {record && record.phoneNumber ? record.phoneNumber : "----"}
        </span>
      ),
      key: "PhoneNo",
      width: 200,
    },
    {
      title: "Specialty",
      render: (record) => (
        <div>
          {record &&
          record.specialtiesInfo &&
          record.specialtiesInfo.length > 0 ? (
            <ShowModal
              record={record}
              modalName={"openSpecialtyBaseModal_Base_Modal"}
              buttonName="View"
            />
          ) : (
            " ----- "
          )}
        </div>
      ),
      key: "specialty",
    },
    {
      title: "Location",
      render: (record) => (
        <span>{record && record.location ? record.location : "----"}</span>
      ),
      key: "Location",
      width: 200,
    },
    {
      title: "CKB (Primary)",
      render: (record) => (
        <span>
          {record && record.conditionInfoPrimaryName
            ? record.conditionInfoPrimaryName
            : "----"}
        </span>
      ),
      key: "conditionInfoPrimaryName",
      width: 200,
    },

    {
      title: "CKB (Secondary)",
      render: (record) => (
        <div>
          {record && record.conditionInfo && record.conditionInfo.length > 0 ? (
            <ShowModal
              record={record}
              modalName={"Knowledge_Base_Modal"}
              buttonName="View"
            />
          ) : (
            " ---- "
          )}
        </div>
      ),
      key: "knowledgeBase",
    },
    {
      title: "Social",
      render: (record) => (
        <ShowModal
          record={record}
          modalName={"social_media_Modal"}
          buttonName="View"
        />
      ),
      key: "social",
    },
    {
      title: "Status",
      render: (record) => (
        <span>
          {renderStatus(record && record.status ? record.status : "active")}
        </span>
      ),
      key: "status",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];
  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <h4
            className="gx-mb-0 gx-font-gilory-bold"
            style={{ color: "#14244E" }}
          >
            SPECIALIST
          </h4>
        }
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getSpecialist"
              recordName="specialistList"
              isShowAll={true}
              searchPlaceHolder="Search By Name/Email"
              selectLabel="label"
              searchCustomQuery={{ query: "search" }}
              selectItemValue="status"
              selectPlaceHolder="Search By Status"
              options={[
                { status: "deleted", label: "Deleted" },
                { status: "active", label: "Active" },
                { status: "block", label: "Blocked" },
                { status: "invite", label: "Invite" },
              ]}
              selectSearchKey="status"
              selectInputWidth={150}
              searchInputWidth={200}
            />

            <AddSpecialist />
          </div>
        }
      >
        <SpecialistTable
          scroll={{ x: 600 }}
          apiName="getSpecialist"
          recordName="specialistList"
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditInvite />
      {profile && location && <EditSpecialist />}
      <KnowledgeBaseModal />
      <SocialMediaModal />
      <SpecialtyBaseModal />
      <ResetPasswordModal />
    </Layout>
  );
};

export default Index;
