import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import ModeratorForm from "./ModeratorForm";

const AddNewModerator = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Add_Moderator_Modal, Loader } = curdProps;

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Moderator"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addModerator"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Moderator_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Moderator_Modal ? Add_Moderator_Modal : false}
      width={550}
      recordName="moderatorList"
      form={form}
    >
      <ModeratorForm />
    </FormModal>
  );
};

export default AddNewModerator;
