import React from "react";
import { Col, Form, Row, Input } from "antd";

const NatureOfVisitForm = () => {
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="natureOfVisitName"
          label="Nature of visit"
          rules={[
            {
              required: true,
              message: "please enter nature of visit!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NatureOfVisitForm;
