import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import ConditionForm from "./ConditionForm";

const EditCondition = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditCondition_Modal, initialValues, Loader } = faqProps;
  const [communityColor, setCommunityColor] = useState("#0D7DD9");
  const [form] = Form.useForm();

  useEffect(() => {
    if (EditCondition_Modal && initialValues && initialValues.communityColor) {
      setCommunityColor(initialValues && initialValues.communityColor);
    }
  }, [EditCondition_Modal, initialValues]);
  return (
    <div>
      <FormModal
        modalTitle="Edit Condition"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editCondition"
        ModalName="EditCondition_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={EditCondition_Modal ? EditCondition_Modal : false}
        initialValues={initialValues}
        width={550}
        form={form}
        extraFieldName={"communityColor"}
        extraFieldValue={communityColor}
        recordName="conditionsList"
      >
        <ConditionForm
          setCommunityColor={setCommunityColor}
          communityColor={communityColor}
        />
      </FormModal>
    </div>
  );
};

export default EditCondition;
