import React from "react";
import { Form, Input, Row, Col, Select, DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { Option } = Select;
const CommunityForm = () => {
  const crudProps = useSelector((state) => state.CurdR);
  const { allCommunities } = crudProps;

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="community"
          label="Community"
          rules={[
            {
              required: true,
              message: "please select Community",
            },
          ]}
        >
          <Select>
            {allCommunities &&
              allCommunities.map((item) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.communityName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Subject"
          rules={[
            {
              required: true,
              message: "please enter subject",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="tags"
          label="Tags"
          rules={[
            {
              required: true,
              message: "please enter subject",
            },
          ]}
        >
          <Select
            open={false}
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags Mode"
          ></Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="publishDate"
          label="Calender"
          rules={[
            {
              required: true,
              message: "Date is required",
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) =>
              current.isBefore(moment().subtract(1, "day"))
            }
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CommunityForm;
