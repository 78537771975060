import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import AlternateTherapyForm from "./AlternateTherapyForm";

const AddAlternateTherapy = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_AlternateTherapy_Modal, Loader } = faqProps;

  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="ADD NATURE OF VISIT"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addAlternateTherapy"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_AlternateTherapy_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_AlternateTherapy_Modal ? Add_AlternateTherapy_Modal : false}
      width={550}
      form={form}
      recordName="alternateTherapyList"
    >
      <AlternateTherapyForm />
    </FormModal>
  );
};

export default AddAlternateTherapy;
