import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import EmojiForm from "./EmojiForm";

const AddEmoji = () => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Emoji_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Emoji"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addEmoji"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Emoji_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Emoji_Modal ? Add_Emoji_Modal : false}
      width={550}
      form={form}
      recordName="emojiList"
      extraFieldName="emoji"
      extraFieldValue={chosenEmoji}
    >
      <EmojiForm chosenEmoji={chosenEmoji} setChosenEmoji={setChosenEmoji} />
    </FormModal>
  );
};

export default AddEmoji;
