import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row, Col, Button, Spin, message } from "antd";
import { DataRequestAction } from "redux/actions/http";
const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const { Reset_User_Password_Modal, initialValues } = useSelector(
    (state) => state.CurdR
  );
  const { ResetLoading, resetSuccess, resetFail } = useSelector(
    (state) => state.auth
  );
  const changePassword = (values) => {
    values.userId = initialValues && initialValues._id;
    dispatch(
      DataRequestAction(
        "PUT",
        "changeUserPassword",
        values,
        "",
        "resetSpinner",
        false
      )
    );
  };

  if (resetSuccess) {
    dispatch({
      type: "update_auth",
    });
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: "Reset_User_Password_Modal",
    });
    message.success("Password reset Successfully");
  }
  if (resetFail) {
    dispatch({
      type: "update_auth",
    });
    message.error("request Fail");
  }

  return (
    <SimpleModal
      width={500}
      title="Reset Password"
      modalFor="Reset_User_Password_Modal"
      visible={Reset_User_Password_Modal ? Reset_User_Password_Modal : false}
    >
      <Form layout="vertical" name="resetPassword" onFinish={changePassword}>
        <Row className="gx-d-flex gx-flex-row">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Confirm Password required",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Confirm Password is required");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
              <Form.Item className="gx-mb-0">
                <Button
                  type="default"
                  className="gx-pt-0 gx-mb-0 gx-mr-2"
                  htmlType="button"
                  onClick={() =>
                    dispatch({
                      type: "Hide_Modal",
                      payload: null,
                      ModalName: "Reset_User_Password_Modal",
                    })
                  }
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item className="gx-mb-0">
                {ResetLoading && (
                  <Spin>
                    <Button type="primary" className="gx-pt-0 gx-mb-0">
                      Reset
                    </Button>
                  </Spin>
                )}
                {!ResetLoading && (
                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0"
                    htmlType="submit"
                  >
                    Reset
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </SimpleModal>
  );
};

export default ResetPasswordModal;
