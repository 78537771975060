import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import CharacterOfPainForm from "./CharacterOfPainForm";
const EditCharacterOfPain = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_CharacterOfPain_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Character of Pain"
   
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editCharacterOfPain"
        ModalName="Edit_CharacterOfPain_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={
          Edit_CharacterOfPain_Modal ? Edit_CharacterOfPain_Modal : false
        }
        initialValues={initialValues}
        width={550}
        form={form}
        recordName="characterOfPainList"
      >
        <CharacterOfPainForm />
      </FormModal>
    </div>
  );
};

export default EditCharacterOfPain;
