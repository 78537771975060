import React from "react";
import { Route, Switch } from "react-router-dom";
import UnderConstruction from "./UnderConstruction";
import CharacterOfPain from "./Admin/Journal/CharacterOfPain";
import TriggerTypes from "./Admin/Journal/Trigger/Types";
import TriggerQuestions from "./Admin/Journal/Trigger/Questions";
import TriggerTips from "./Admin/Journal/Trigger/Tips";
import TriggerExercises from "./Admin/Journal/Trigger/Exercises";
import PainManagementSpecialistPhysician from "./Admin/Journal/PainManagementSpecialist/Physician";
import PainManagementSpecialistNatureOfVisit from "./Admin/Journal/PainManagementSpecialist/NatureOfVisit";
import ConservativeCare from "./Admin/Journal/ConservativeCare";
import AlternateTherapy from "./Admin/Journal/AlternateTherapy";
import SelfTherapy from "./Admin/Journal/SelfTherapy";
import DrugCategory from "./Admin/Journal/DrugTherapy/DrugCategory";
import DrugName from "./Admin/Journal/DrugTherapy/DrugName";
import OverTheCounter from "./Admin/Journal/DrugTherapy/OverTheCounter";
import Emoji from "./Admin/Journal/Emoji";
import Specialist from "./Admin/Specialist";
import EducationTopic from "./Admin/Education/Topic";
import EducationCategory from "./Admin/Education/Category";
import exerciseCategory from "./Admin/Setting/Exercise/Category";
import exerciseExe from "./Admin/Setting/Exercise/Exercises";
import staticPages from "./Admin/Setting/StaticPage";
import Profile from "./Admin/Setting/Profile";
import Symptom from "./Admin/Setting/Symptoms";
import Supplement from "./Admin/Setting/Supplement";
import Vitamin from "./Admin/Setting/Vitamin";
import Category from "./Admin/Setting/HealthCare/Category";
import Occupation from "./Admin/Setting/HealthCare/Occupation";
import CommunityList from "./Admin/Community/CommunityList";
import communityDiscussion from "./Admin/Community/CommunityDiscussion";
import CommentDetail from "./Admin/Community/CommunityDiscussion/CommentDetail";
import User from "./Admin/Team/User";
import Moderator from "./Admin/Team/Moderator";
import MyMedicalCondition from "./Admin/MyMedicalCondition/Conditions";
import MyMedicalConditionCategory from "./Admin/MyMedicalCondition/Category";
import MyMedicalConditionArticle from "./Admin/MyMedicalCondition/Article";
import SignupExercise from "./HighSuperAdmin/SignupExercise";
import AppNotification from "./Admin/AppNotification";
import ModeratorPage from "./Admin/PotalPages/Modenator";
import SpecialistPage from "./Admin/PotalPages/specialist";
import SelfCategory from "./Admin/SelfCopingTool/Category";
import SelfExercise from "./Admin/SelfCopingTool/ExercisesDetail";
import Package from './Admin/Packages'
import GamificationPoint from './Admin/Gamification/GamificationPoint'
import GamificationSetting from './Admin/Gamification/GamificationSetting'
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}dashboard`}
        component={UnderConstruction}
      />

      <Route
        exact
        path={`${match.url}journal/characterOfPain`}
        component={CharacterOfPain}
      />
      <Route
        exact
        path={`${match.url}journal/trigger/types`}
        component={TriggerTypes}
      />
      <Route
        exact
        path={`${match.url}journal/trigger/questions`}
        component={TriggerQuestions}
      />
      <Route
        exact
        path={`${match.url}journal/trigger/tips`}
        component={TriggerTips}
      />
      <Route
        exact
        path={`${match.url}journal/trigger/exercises`}
        component={TriggerExercises}
      />
      <Route
        exact
        path={`${match.url}journal/painManagementSpecialist/physician`}
        component={PainManagementSpecialistPhysician}
      />
      <Route
        exact
        path={`${match.url}journal/painManagementSpecialist/natureOfVisit`}
        component={PainManagementSpecialistNatureOfVisit}
      />
      <Route
        exact
        path={`${match.url}journal/CAITCare/conservativeCare`}
        component={ConservativeCare}
      />
      <Route
        exact
        path={`${match.url}journal/CAITCare/alternateTherapy`}
        component={AlternateTherapy}
      />
      <Route
        exact
        path={`${match.url}journal/selfTherapy`}
        component={SelfTherapy}
      />
      <Route
        exact
        path={`${match.url}journal/drugTherapy/drugCategory`}
        component={DrugCategory}
      />
      <Route
        exact
        path={`${match.url}journal/drugTherapy/drugName`}
        component={DrugName}
      />
      <Route
        exact
        path={`${match.url}journal/drugTherapy/otc`}
        component={OverTheCounter}
      />
      <Route exact path={`${match.url}journal/emoji`} component={Emoji} />
      <Route exact path={`${match.url}specialist`} component={Specialist} />
      <Route
        exact
        path={`${match.url}education/topic`}
        component={EducationTopic}
      />
      <Route
        exact
        path={`${match.url}education/category`}
        component={EducationCategory}
      />
      <Route
        exact
        path={`${match.url}setting/exercise/category`}
        component={exerciseCategory}
      />
      <Route
        exact
        path={`${match.url}setting/exercise/exercises`}
        component={exerciseExe}
      />
      <Route
        exact
        path={`${match.url}setting/staticPages`}
        component={staticPages}
      />
      <Route exact path={`${match.url}setting/symptom`} component={Symptom} />
      <Route
        exact
        path={`${match.url}setting/supplement`}
        component={Supplement}
      />
      <Route exact path={`${match.url}setting/vitamin`} component={Vitamin} />
      <Route exact path={`${match.url}setting/profile`} component={Profile} />
      <Route
        exact
        path={`${match.url}setting/healthCare/category`}
        component={Category}
      />
      <Route
        exact
        path={`${match.url}setting/healthCare/occupation`}
        component={Occupation}
      />
      <Route exact path={`${match.url}moderator`} component={Moderator} />

      <Route
        exact
        path={`${match.url}community/communityList`}
        component={CommunityList}
      />
      <Route
        exact
        path={`${match.url}community/communityDiscussion`}
        component={communityDiscussion}
      />
      <Route
        exact
        path={`${match.url}community/communityDiscussion/commentDetail`}
        component={CommentDetail}
      />
      <Route exact path={`${match.url}team/user`} component={User} />
      <Route exact path={`${match.url}team/moderator`} component={Moderator} />
      <Route
        exact
        path={`${match.url}myMedicalCondition/condition`}
        component={MyMedicalCondition}
      />
      <Route
        exact
        path={`${match.url}myMedicalCondition/category`}
        component={MyMedicalConditionCategory}
      />
      <Route
        exact
        path={`${match.url}myMedicalCondition/article`}
        component={MyMedicalConditionArticle}
      />
      <Route
        exact
        path={`${match.url}signupAccountExercise`}
        component={SignupExercise}
      />
      <Route
        exact
        path={`${match.url}portal/moderatorPage`}
        component={ModeratorPage}
      />
      <Route
        exact
        path={`${match.url}portal/specialistPage`}
        component={SpecialistPage}
      />
      <Route
        exact
        path={`${match.url}appNotification`}
        component={AppNotification}
      />
      <Route
        exact
        path={`${match.url}selfCopingTool/category`}
        component={SelfCategory}
      />
      <Route
        exact
        path={`${match.url}selfCopingTool/exerciseDetail`}
        component={SelfExercise}
      />
      <Route exact path={`${match.url}package`} component={Package} />
      <Route exact path={`${match.url}gamification/gamificationPoint`} component={GamificationPoint} />
      <Route exact path={`${match.url}gamification/gamificationSetting`} component={GamificationSetting} />
    </Switch>
  </div>
);

export default App;
