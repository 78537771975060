import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import CharacterOfPainTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditCharacterOfPain from "./components/EditAccountExercise";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddCharacterOfPain from "./components/AddAccountExercise";
import ShowModal from "components/Modal/ShowModal";

const Index = () => {
  const columns = [
    {
      title: "Exercise",
      render: (record) => <span>{record.exerciseName}</span>,
      key: "symptomName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Account_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteAccountExercise"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="accountExList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Exercise?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Signup Account Exercise"
        extra={<AddCharacterOfPain />}
      >
        <CharacterOfPainTable
          apiName="getAccountExercise"
          recordName="accountExList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditCharacterOfPain />
    </Layout>
  );
};
export default Index;
