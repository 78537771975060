import React, { useEffect } from "react";
import Widget from "components/Widget";
import OccupationList from "components/Table/List";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { useDispatch, useSelector } from "react-redux";
import EditOccupation from "./components/EditOccupation";
import AddNewOccupation from "./components/AddOccupation";
import { DataGetAction } from "redux/actions/CommonHttp";
import Filters from "components/Filters/SearchAndSelectFilter";
const Occupation = () => {
  const dispatch = useDispatch();
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allHealthCategories } = curdPropsProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getHealthCategory",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allHealthCategories"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: " Category",
      render: (record) => <span>{record.categoryName}</span>,
      key: "category",
      width: "200px",
    },
    {
      title: "Exercise",
      render: (record) => <span>{record.occupationName}</span>,
      key: "exercise",
    },
    {
      title: " ",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Occupation_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteOccupation"
          recordName="occupationList"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this occupation?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="OCCUPATION"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getOccupations"
              recordName="occupationList"
              isShowAll={true}
              searchPlaceHolder="Search By Exercises"
              selectLabel="categoryName"
              selectItemValue="_id"
              searchCustomQuery={{ query: "search", key: "name" }}
              selectPlaceHolder="Search by Type"
              options={allHealthCategories}
              selectSearchKey="catId"
              selectInputWidth={150}
              searchInputWidth={200}
            />
            <AddNewOccupation />
          </div>
        }
      >
        <OccupationList
          recordName="occupationList"
          apiName="getOccupations"
          columns={columns}
        />
      </Widget>
      <EditOccupation />
    </Layout>
  );
};
export default Occupation;
