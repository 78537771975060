import React from "react";
import { Form, Input, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;
const QuestionForm = () => {
  const CurdRProps = useSelector((state) => state.CurdR);
  const { allTriggerTypes } = CurdRProps;
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="triggerTypeId"
          label="Trigger Type"
          rules={[
            {
              required: true,
              message: "please enter the trigger type!",
            },
          ]}
        >
          <Select>
            {allTriggerTypes &&
              allTriggerTypes.map((item) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.triggerTypeName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="triggerQuestionName"
          label="Trigger Question"
          rules={[
            {
              required: true,
              message: "please enter the trigger question!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default QuestionForm;
