import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
const SpecialistModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { openSpecialtyBaseModal_Base_Modal, initialValues } = CurdProps || {};
  const renderKnowledgeBase = (conditions) => {
    return (
      conditions &&
      conditions.map((item) => {
        if (item.occupationName !== "") {
          return (
            <span className="gx-d-block gx-mb-1">{item.occupationName}</span>
          );
        }
        return item;
      })
    );
  };

  return (
    <>
      <SimpleModal
        visible={
          openSpecialtyBaseModal_Base_Modal
            ? openSpecialtyBaseModal_Base_Modal
            : false
        }
        modalFor="openSpecialtyBaseModal_Base_Modal"
        width={500}
        title="Specialties Modal"
      >
        <div
          style={{
            padding: 10,
            borderColor: "grey",
            backgroundColor: "#fafafa",
            borderRadius: "10px",
          }}
        >
          {renderKnowledgeBase(initialValues && initialValues.specialtiesInfo)}
        </div>
      </SimpleModal>
    </>
  );
};

export default SpecialistModal;
