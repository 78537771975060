import React from "react";
import { Form, Input, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;

const OccupationForm = () => {
  const pageProps = useSelector((state) => state.CurdR);
  const { allHealthCategories } = pageProps;

  return (
    <Row className="gx-d-flex gx-flex-row">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="categoryId"
          label="Category Name"
          rules={[
            {
              required: true,
              message: "Pleas Select the Category!",
            },
          ]}
        >
          <Select>
            {allHealthCategories &&
              allHealthCategories.map((item) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.categoryName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="occupationName"
          label="Occupation Name"
          rules={[
            {
              required: true,
              message: "Pleas enter the Occupation name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OccupationForm;
