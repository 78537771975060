import React, { useEffect, useState } from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import { Tree } from "antd";
const { DirectoryTree } = Tree;
const TreeModal = () => {
  const [treeData, setTreeData] = useState([]);
  const CurdProps = useSelector((state) => state.CurdR);
  const { Open_Tree_Modal, initialValues } = CurdProps || {};

  const setData = () => {
    if (initialValues) {
      initialValues &&
        initialValues.children &&
        initialValues.children.forEach((item, index) => {
          let parentKey = item._id;
          item["key"] = parentKey;
          item["title"] = item.name;

          if (item.children) {
            item.children.forEach((Item) => {
              let childKey = Item._id;
              Item["key"] = parentKey + "-" + childKey;
              Item["title"] = Item.name;

              if (Item.children) {
                Item.children.forEach((singleItem) => {
                  let grandChildKey = singleItem._id;
                  singleItem["key"] =
                    parentKey + "-" + childKey + "-" + grandChildKey;
                  singleItem["title"] = singleItem.name;
                });
              }
            });
          }
        });
      setTreeData(initialValues.children);
    }
  };
  useEffect(setData, [initialValues]);
  return (
    <SimpleModal
      visible={Open_Tree_Modal ? Open_Tree_Modal : false}
      modalFor="Open_Tree_Modal"
      width={500}
      title={"Sub categories"}
    >
      {treeData && (
        <DirectoryTree multiple defaultExpandAll treeData={treeData} />
      )}
    </SimpleModal>
  );
};

export default TreeModal;
