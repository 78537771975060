import React from "react";
import FormModal from "components/Modal/EditFormModal";
import { Form } from "antd";
import SupplementForm from "./SupplementForm";
import { useSelector } from "react-redux";

const EditSupplement = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { Edit_Supplement_Modal, Loader, initialValues } = curdProps;

  return (
    <FormModal
      modalTitle="Edit Supplement"
      basicButtonTitle="Edit"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editSupplement"
      ModalName="Edit_Supplement_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Supplement_Modal ? Edit_Supplement_Modal : false}
      buttonStyleClass="gx-buttonStyleClass"
      isShowButton={false}
      initialValues={initialValues}
      width={500}
      form={form}
      recordName="supplementList"
    >
      <SupplementForm />
    </FormModal>
  );
};

export default EditSupplement;
