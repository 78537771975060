import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import ExerciseForm from "./ExerciseForm";

const AddNewExercise = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Exercise_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Exercise"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addExercise"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Exercise_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Exercise_Modal ? Add_Exercise_Modal : false}
      width={550}
      form={form}
      recordName="exercisesList"
    >
      <ExerciseForm />
    </FormModal>
  );
};

export default AddNewExercise;
