import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AlternateTherapyTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditNotification from "./components/EditNotification";
import TableAction from "components/Table/Actions/ActionDropdown";
import ShowModal from "components/Modal/ShowModal";
import AddNotification from "./components/AddNotification";
import { useSelector } from "react-redux";

const Index = () => {
  const notificationProps = useSelector((state) => state.CurdR);
  const { notificationList } = notificationProps;
  const columns = [
    {
      title: "Notification",
      render: (record) => <span>{record.notification}</span>,
      key: "notification",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Notification_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteNotification"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="notificationList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Notification?"
          Icon="Delete"
        />
      </div>
    );
  };

  const notificationLength =
    notificationList && notificationList.length ? notificationList.length : 0;

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="App Notification"
        extra={notificationLength < 3 && <AddNotification />}
      >
        <AlternateTherapyTable
          apiName="getNotification"
          recordName="notificationList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditNotification />
    </Layout>
  );
};
export default Index;
