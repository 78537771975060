import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import CommunityForm from "./CommunityForm";

const AddCommunity = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { AddCoummunity_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  const [communityColor, setCommunityColor] = useState('#0D7DD9')
  return (
    <div>
      <FormModal
        addButtonName="Add New"
        modalTitle="Add Community"
        submitButtonText="Save"
        cancelButtonText="Cancel"
        apiMethod="POST"
        addApiName="addCommunity"
        addApiRequest="AddRecord"
        apiLoader="StartSpinner"
        recordName="communityList"
        ModalName="AddCoummunity_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={AddCoummunity_Modal ? AddCoummunity_Modal : false}
        width={500}
        form={form}
      >
        <CommunityForm setCommunityColor={setCommunityColor} communityColor={communityColor} />
      </FormModal>
    </div>
  );
};

export default AddCommunity;
