import React from "react";
import { Form, Input, Row, Col, Select, Checkbox } from "antd";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const ModeratorForm = () => {
  const { Edit_Moderator_Modal, communitySelect } = useSelector(
    (state) => state.CurdR
  );
  const renderOptions = () => {
    if (communitySelect && communitySelect.length > 0) {
      return communitySelect.map((item) => (
        <Option value={item._id}>{item.communityName}</Option>
      ));
    }
  };

  const plainOptions = [
    "Ran facebook group",
    "Ran instagram",
    "In person group",
  ];

  return (
    <div>
      <Row className="gx-d-flex gx-flex-row gx-align-items-center">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="fullName"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!Edit_Moderator_Modal && (
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Enter Email!",
                },
              ]}
            >
              <Input className="gx-text-lowercase" />
            </Form.Item>
          )}
          <Form.Item
            name="communities"
            label="Assign Community"
            rules={[
              {
                required: true,
                message: " Enter Type!",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select"
              style={{ width: "100%" }}
            >
              {renderOptions()}
            </Select>
          </Form.Item>

          <Form.Item name="description" label="Brief Bio">
            <TextArea />
          </Form.Item>
          <span>Experience with online moderating</span>

          <Form.Item
            name="moderatorExperience"
            className="gx-mb-1"
            valuePropName="checked"
          >
            <CheckboxGroup options={plainOptions} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ModeratorForm;
