import React, { useEffect } from "react";
import Widget from "components/Widget";
import CommunityDiscussionList from "components/Table/List";
import Layout from "components/Layout";
import TableAction from "components/Table/Actions/ActionDropdown";
import { useDispatch, useSelector } from "react-redux";
import DetailSubjectModal from "./components/DetailSubjectModal";
import AddNewModal from "./components/AddNewModal";
import EditModal from "./components/EditModal";
import CommentModal from "./components/CommentModal";
import ShowModal from "components/Modal/ShowModal";
import { DataGetAction } from "redux/actions/CommonHttp";
import Filters from "components/Filters/SearchAndSelectFilter";
import { renderArticle, renderMoreHtmlDec } from "utils/commonUseFunction";
import DeleteConfirm from "../../../../components/Modal/DeleteConfirm";
import { renderStatus } from "../../../../utils/commonUseFunction";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const { fetchSuccess, allCommunities, Open_Comment_Modal } = useSelector((state) => state.CurdR);
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(DataGetAction("getCommunity", "FetchRecord", { query: "all" }, "", "", "allCommunities"));
  };

  useEffect(fetchList, []);

  const openTheCommentDetailPage = (data) => {
    
    history.push({
      pathname: "/community/communityDiscussion/commentDetail",
      state: {
        communityId:data.community,
        discussionId: data._id,
        topicOfDay: data.title,
        createdBy: data.createdBy,
        totalComments: data.totalComments,
        totalEmpathies: data.totalEmpathies,
        RecipeCount: data.RecipeCount,
        ExerciseCount: data.ExerciseCount,
      },
    });
  };

  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.CDId}</span>,
      key: "id",
    },
    {
      title: "Creator",
      render: (record) => <span>{record.createdBy}</span>,
      key: "user",
    },
    {
      title: "Community",
      render: (record) => <span>{record.communityName}</span>,
      key: "community",
    },

    {
      title: "Subject & Text",
      render: (record) => (
        <span>
          {renderArticle(record && record.title)}
          {renderMoreHtmlDec(record && record.title, "Open_Subject_Modal", dispatch)}
        </span>
      ),
      key: "community",
    },

    {
      title: "Comments",
      render: (record) => (
        <Button type="link" onClick={() => openTheCommentDetailPage(record)}>
          <span role="img" aria-label="flower">
            💬
          </span>
          <span className="gx-ml-2">{record.comments}</span>
        </Button>
        // <ShowModal
        //   record={record}
        //   modalName={"Open_Comment_Modal"}
        //   buttonName={

        //   }
        // />
      ),
      key: "comments",
    },
    { title: "Status", render: (record) => <span>{renderStatus(record.status)}</span> },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal className="gx-px-4" record={record} modalName={"Edit_CDiscussion_Modal"} buttonName="Edit" />
        <DeleteConfirm
          type="deleteCommunityDiscussion"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="communityList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Community Discussion?"
          content=""
          Icon={
            <span
              className="gx-font-gilory-regular gx-link gx-pl-4"
              style={{
                fontSize: "14px",
                color: "#545454",
                verticalAlign: "top",
              }}
            >
              Delete
            </span>
          }
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="COMMUNITY DISCUSSION"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getCommunityDiscussion"
              recordName="communityDiscussionList"
              isShowAll={true}
              searchPlaceHolder="Search By Exercise"
              selectLabel="communityName"
              selectItemValue="_id"
              selectPlaceHolder="Search by Type"
              options={allCommunities}
              searchCustomQuery={{ query: "search", key: "keyword" }}
              selectSearchKey="communitySearch"
              selectInputWidth={150}
              searchInputWidth={200}
            />
            <AddNewModal />
          </div>
        }
      >
        <CommunityDiscussionList
          callOnCondition={fetchSuccess}
          apiName="getCommunityDiscussion"
          recordName="communityDiscussionList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <DetailSubjectModal />
      <EditModal />
      {Open_Comment_Modal && <CommentModal />}
    </Layout>
  );
};

export default Index;
