import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AlternateTherapyTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditAlternateTherapy from "./components/EditAlternateTherapy";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddAlternateTherapy from "./components/AddAlternateTherapy";
import ShowModal from "components/Modal/ShowModal";
import { useDispatch } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
import SearchBox from "components/Filters/SearchBox";
const Index = () => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getTriggerTypes",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allTriggerTypes"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Alternate Therapy Name",
      render: (record) => <span>{record.alternateTherapyName}</span>,
      key: "alternateTherapyName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_AlternateTherapy_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteAlternateTherapy"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="alternateTherapyList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this nature of visit?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="NATURE OF VISIT"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getAlternateTherapy"
              recordName="alternateTherapyList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Name"
              width="200px"
            />

            <AddAlternateTherapy />
          </div>
        }
      >
        <AlternateTherapyTable
          apiName="getAlternateTherapy"
          recordName="alternateTherapyList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditAlternateTherapy />
    </Layout>
  );
};
export default Index;
