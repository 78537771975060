import React, { useEffect } from "react";
import Widget from "components/Widget";
import ModeratorList from "components/Table/List";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import Layout from "components/Layout";
import Filters from "components/Filters/SearchAndSelectFilter";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditModerator from "./components/EditModerator";
import ResetPassword from "./components/ResetPassword";
import ShowModal from "components/Modal/ShowModal";
import AddNewModerator from "./components/AddModerator";
import { renderStatus } from "utils/commonUseFunction";
import { useDispatch } from "react-redux";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
const Moderator = () => {
  const dispatch = useDispatch();
  const fetchCommunityList = () => {
    dispatch(
      DataGetAction(
        "getCommunity",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "communitySelect"
      )
    );
  };
  useEffect(fetchCommunityList, []);
  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.userId}</span>,
      key: "ID",
      width: "80px",
    },
    {
      title: "FullName",
      render: (record) => <span>{record.fullName}</span>,
      key: "FullName",
      width: "250px",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email}</span>,
      key: "Email",
      width: "300px",
    },
    
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "Status",
    },
    {
      title: "",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const updateUserStatus = (data, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateModerator",
        "EditRecord",
        { _id: data._id, status: status },
        "",
        "",
        "moderatorList"
      )
    );
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column gx-pointer ">
        {record.status !== "block" && record.status !== "deleted" && (
          <span
            className="action-link  gx-link"
            onClick={() => updateUserStatus(record, "block")}
          >
            Block
          </span>
        )}
        {record.status !== "active" && (
          <span
            className="action-link  gx-link"
            onClick={() => updateUserStatus(record, "active")}
          >
            Active
          </span>
        )}
        {record.status === "active" && (
          <ShowModal
            record={record}
            modalName={"Edit_Moderator_Modal"}
            buttonName="Edit"
          />
        )}
        {record.status === "active" && (
          <ShowModal
            record={record}
            modalName={"Reset_Moderator_Password_Modal"}
            buttonName="Reset Password"
          />
        )}
        <DeleteConfirm
          type="deleteModerator"
          recordName="moderatorList"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Moderator?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Moderator"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getModerator"
              recordName="moderatorList"
              isShowAll={true}
              searchPlaceHolder="Search By Name/Email"
              selectLabel="label"
              selectItemValue="status"
              selectPlaceHolder="Search by Status"
              options={[
                { status: "active", label: "Active" },
                { status: "block", label: "Block" },
              ]}
              selectSearchKey="status"
              selectInputWidth={150}
              searchInputWidth={200}
            />
            <AddNewModerator />
          </div>
        }
      >
        <ModeratorList
          recordName="moderatorList"
          apiName="getModerator"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <ResetPassword />
      <EditModerator />
    </Layout>
  );
};

export default Moderator;
