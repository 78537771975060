import React from "react";
import { Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import ArticleForm from "./ArticleForm";
// import { DataGetAction } from "../../../../../redux/actions/CommonHttp";

const AddNewQuestion = () => {
  const dispatch = useDispatch();
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_Article_Modal, initialValues, Loader, success } = faqProps;
  const [form] = Form.useForm();
  if (success) {
    dispatch({
      type: "ResetAll_State",
    });
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: "Edit_Article_Modal",
    });

    message.success("Edit Record Successfully");
  }

  return (
    <FormModal
      modalTitle="Edit Article"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecordPagination"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editMedicalArticle"
      ModalName="Edit_Article_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Article_Modal ? Edit_Article_Modal : false}
      initialValues={initialValues}
      width={600}
      recordName="articleList"
      form={form}
    >
      <ArticleForm />
    </FormModal>
  );
};

export default AddNewQuestion;
