import React from "react";
import FormModal from "components/Modal/EditFormModal";
import EducationForm from "./EducationForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
const AddNewQuestion = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { EditEducation_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit Topic"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editEducation"
        ModalName="EditEducation_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={EditEducation_Modal ? EditEducation_Modal : false}
        initialValues={initialValues}
        width={600}
        recordName="eductionList"
        form={form}
      >
        <EducationForm />
      </FormModal>
    </div>
  );
};

export default AddNewQuestion;
