import React from "react";
import Layout from "components/Layout";
const UnderConstruction = () => {
  return (
    <Layout>
      <div
        style={{ height: "100vh" }}
        className="gx-d-flex gx-flex-column gx-justify-content-center gx-align-items-center"
      >
        <h1>This page is Under Construction</h1>
        <h6>
          Please check with us in near future for more updates related to this.
        </h6>
      </div>
    </Layout>
  );
};

export default UnderConstruction;
