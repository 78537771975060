import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import CategoryTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditModal from "./components/EditCategory";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddCategory from "./components/AddCategory";
import TreeModal from "./components/TreeModal";
import ShowModal from "components/Modal/ShowModal";
import SelectFilter from "components/Filters/SelectFilter";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const Category = () => {
  const dispatch = useDispatch();
  const CurdProps = useSelector((state) => state.CurdR);
  const { categoriesForFilter, initialValues } = CurdProps;

  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getEducationCategory",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "categoriesForFilter"
      )
    );
  };
  useEffect(fetchList, []);

  const columns = [
    {
      title: "No",
      render: (record) => <span>{record.categoryNo}</span>,
      key: "categoryNo",
    },
    {
      title: "Category",
      render: (record) => <span>{record.name}</span>,
      key: "categoryName",
    },

    {
      title: "Sub Category",
      render: (record) => (
        <ShowModal
          record={record}
          modalName={"Open_Tree_Modal"}
          buttonName="View"
        />
      ),
      key: "subcategory",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"EditCategory_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteCategory"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          recordName="categoryList"
          oKText="Delete"
          title="Are you sure you want to delete this Category?"
          Icon="Delete"
        />
      </div>
    );
  };
  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Category"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SelectFilter
              recordName="categoryList"
              isShowingAll={true}
              className="select-filter"
              apiName="getEducationCategory"
              searchKey="catId"
              options={categoriesForFilter}
              placeholder="Search by category"
              ItemValue="_id"
              label="name"
              width="200px"
            />
            <AddCategory />
          </div>
        }
      >
        <CategoryTable
          apiName="getEducationCategory"
          recordName="categoryList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      {initialValues && initialValues.categoryImage !== null && <EditModal />}
      <TreeModal />
    </Layout>
  );
};
export default Category;
