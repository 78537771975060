import React from "react";
import FormModal from "../../../../components/Modal/FormModal";
import SpecialistForm from "./SpecialistForm";
import { Form } from "antd";
import { useSelector } from "react-redux";

const AddSpecialist = () => {
  const [form] = Form.useForm();
  const faqProps = useSelector((state) => state.CurdR);
  const { AddSpecialist_Modal, Loader } = faqProps;

  return (
    <FormModal
      addButtonName="Invite"
      modalTitle="Invite"
      submitButtonText="Invite"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="inviteSpecialist"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="AddSpecialist_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={AddSpecialist_Modal ? AddSpecialist_Modal : false}
      width={450}
      recordName="specialistList"
      form={form}
    >
      <SpecialistForm />
    </FormModal>
  );
};

export default AddSpecialist;
