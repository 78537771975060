import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import CategoryTable from "components/Table/List";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddCategory from "./components/AddCategory";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import SearchBox from "components/Filters/SearchBox";
import EditCategory from "./components/EditCategory";
import ShowModal from "components/Modal/ShowModal";
import ViewModel from "./components/View";
import { useSelector } from "react-redux";
const Index = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { initialValues } = faqProps;
  console.log("====initialValues===", initialValues);
  const columns = [
    {
      title: "No.",
      render: (record) => <span>{record.no}</span>,
      width: "80px",
    },
    {
      title: "Order No",
      render: (record) => (
        <span>{record.orderNo ? record.orderNo : "----"}</span>
      ),
      width: "100px",
    },
    {
      title: "Category Name",
      render: (record) => <span>{record.name}</span>,
      width: "200px",
    },
    {
      title: "Sub Menu Category",
      render: (record) => (
        <ShowModal
          record={record}
          modalName="Open_View_Modal"
          className="gx-link"
          buttonName="View"
        />
      ),
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderAction(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderAction = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Category_model"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteMedicalConditionCategory"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="categoryList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Category?"
          content=""
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        title="Category"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getMedicalConditionCategory"
              recordName="categoryList"
              requestType="FetchRecord"
              placeholder="Category"
              width="200px"
            />
            <AddCategory />
          </div>
        }
      >
        <CategoryTable
          apiName="getMedicalConditionCategory"
          recordName="categoryList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      {initialValues && initialValues.categoryImage !== null && (
        <EditCategory />
      )}
      <ViewModel />
    </Layout>
  );
};

export default Index;
