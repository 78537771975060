import React, { useEffect } from "react";
import SimpleModal from "../../../../../components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../../redux/actions/CommonHttp";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { Empty } from "antd";
const LeaderBoardModelGraph = () => {
  const dispatch = useDispatch();
  const { View_Leaderboard_Graph_Modal, initialValues, leaderBoardList } =
    useSelector((state) => state.CurdR);

  useEffect(() => {
    // dispatch(
    //   DataGetAction(
    //     "getLeaderBoard",
    //     "FetchRecord",
    //     { query: "all", _id: initialValues && initialValues._id },
    //     "",
    //     "",
    //     "leaderBoardList"
    //   )
    // );
  }, [View_Leaderboard_Graph_Modal]);
  const data = [
    {
      subject: "Math",
      A: 120,
    },
    {
      subject: "Chinese",
      A: 98,
    },
    {
      subject: "English",
      A: 86,
    },
    {
      subject: "Geography",
      A: 99,
    },
    {
      subject: "Physics",
      A: 85,
    },
  ];
  return (
    <SimpleModal
      width={"600px"}
      visible={View_Leaderboard_Graph_Modal}
      title="View Leaderboard Graph"
      modalFor="View_Leaderboard_Graph_Modal"
      closable={true}
    >
      <div
        className="gx-d-flex gx-align-items-center gx-justify-content-center"
        style={{ width: "100%", height: "400px" }}
      >
        {initialValues && initialValues.pointHistoryArray && (
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart
              cx="54%"
              cy="50%"
              outerRadius="80%"
              data={initialValues.pointHistoryArray}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <Radar
                name="Mike"
                dataKey="point"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        )}
        {initialValues && !initialValues.pointHistoryArray && (
          <div
            style={{ width: "100%", height: "100%" }}
            className="gx-d-flex gx-align-items-center gx-justify-content-center"
          >
            <Empty />
          </div>
        )}
      </div>
    </SimpleModal>
  );
};

export default LeaderBoardModelGraph;
