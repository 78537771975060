import React from "react";
import { Form, Input, Row, Col, InputNumber } from "antd";
import Picker from "emoji-picker-react";
const EmojiForm = ({ chosenEmoji, setChosenEmoji }) => {
  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };
  return (
    <div>
      <Row className="gx-mx-0 gx-d-flex">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="emojiName"
            label={"Emoji Name"}
            rules={[
              {
                required: true,
                message: "please enter the emoji name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="painLevel"
            label={"Pain Level"}
            rules={[
              {
                required: true,
                message: "please enter the pain level!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <div className="gx-w-100">
            {chosenEmoji ? (
              <span>You chose: {chosenEmoji.emoji}</span>
            ) : (
              <span>No emoji Chosen</span>
            )}
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmojiForm;
