import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import ExerciseTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditExercises from "./components/EditExercise";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddExercise from "./components/AddExercise";
import ShowModal from "components/Modal/ShowModal";
import Filters from "components/Filters/SearchAndSelectFilter";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
const Exercise = () => {
  const dispatch = useDispatch();
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allCategory } = curdPropsProps;
  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getExerciseCategory",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "allCategory"
      )
    );
  };
  useEffect(fetchList, []);
  const columns = [
    {
      title: "Category",
      render: (record) => <span>{record.categoryName}</span>,
      key: "categoryName",
    },
    {
      title: "Exercise",
      render: (record) => <span>{record.exerciseName}</span>,
      key: "exerciseName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"EditExercise_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteExercise"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          recordName="exercisesList"
          oKText="Delete"
          title="Are you sure you want to delete this Exercise?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title="Exercises"
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <Filters
              apiName="getExercises"
              recordName="exercisesList"
              isShowAll={true}
              searchPlaceHolder="Search By Exercise"
              selectLabel="categoryName"
              selectItemValue="_id"
              selectPlaceHolder="Search by Type"
              options={allCategory}
              searchCustomQuery={{ query: "search", key: "name" }}
              selectSearchKey="catId"
              selectInputWidth={150}
              searchInputWidth={200}
            />
            <AddExercise />
          </div>
        }
      >
        <ExerciseTable
          apiName="getExercises"
          recordName="exercisesList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditExercises />
    </Layout>
  );
};
export default Exercise;
