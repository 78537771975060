import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
const ArticleModal = () => {
  const CurdProps = useSelector((state) => state.CurdR);
  const { Knowledge_Base_Modal, initialValues } = CurdProps || {};

  const renderKnowledgeBase = (conditions) => {
    return (
      conditions &&
      conditions.map((item) => {
        return <span className="gx-d-block gx-mb-1">{item.conditionName}</span>;
      })
    );
  };

  return (
    <SimpleModal
      visible={Knowledge_Base_Modal ? Knowledge_Base_Modal : false}
      modalFor="Knowledge_Base_Modal"
      width={500}
      title="Knowledge Base"
    >
      <div
        style={{
          padding: 10,
          borderColor: "grey",
          backgroundColor: "#fafafa",
          borderRadius: "10px",
        }}
      >
        {renderKnowledgeBase(initialValues && initialValues.conditionInfo)}
      </div>
    </SimpleModal>
  );
};

export default ArticleModal;
