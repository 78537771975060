import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import { Row, Col, Avatar, Modal, Button, Collapse, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { renderDate } from "utils/commonUseFunction";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
import { useLocation } from "react-router-dom";
import { ArrowLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ExerciseImage from "../../../../assets/exercise64.png";
import MessageImage from "../../../../assets/Message.png";
import RecipeImage from "../../../../assets/recipe.png";

const { Panel } = Collapse;
const CommentDetail = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [comments, setComments] = useState([]);
  const faqProps = useSelector((state) => state.CurdR);
  const { initialValues, deleteSuccess, CommentDetail, Loader } = faqProps;
  console.log("======= Loader", Loader);
  const commentInfo = initialValues && initialValues.commentInfo;

  const error = (index, data) => {
    Modal.error({
      title: "Are you sure you want to delete this comment?",
      onOk() {
        removeProduct(index, data);
      },
      closable: true,
    });
  };
  const removeProduct = (index, data) => {
    dispatch(
      DataRequestAction(
        "DELETE",
        "deleteComment",
        "DeleteComment",
        { ...data, communityId: state.communityId },
        "",
        "",
        ""
      )
    );
  };

  const removeComment = (id, type, createdBy) => {
    dispatch(
      DataRequestAction(
        "DELETE",
        "deleteEmpathy",
        "DeleteComment",
        { _id: id, type: type, createdBy: createdBy, communityId: state.communityId },
        "",
        "",
        ""
      )
    );
  };

  const fetchDiscussionDetail = () => {
    dispatch(
      DataGetAction(
        "getDiscussionCommentById",
        "FetchRecord",
        { discussionId: state.discussionId },
        "StartSpinner",
        "",
        "CommentDetail"
      )
    );
  };

  useEffect(fetchDiscussionDetail, []);

  const renderTopicOfDayDetail = () => {
    console.log("====== state =====", state);

    const { topicOfDay, totalComments, totalEmpathies, RecipeCount, ExerciseCount } = state;
    return (
      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Button
                type="text"
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowLeftOutlined
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Button>
              <h3 style={{ marginLeft: "10px" }}> {topicOfDay}</h3>
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row", marginTop: "5px" }}>
              <p style={{ marginRight: "5px" }}>Total Comments: {totalComments}</p>
              <p style={{ marginRight: "5px" }}>Total Empathies: {totalEmpathies}</p>
              <p style={{ marginRight: "5px" }}>Total Recipe: {RecipeCount}</p>
              <p style={{ marginRight: "5px" }}>Total Exercise: {ExerciseCount}</p>
            </div> */}
          </div>
        </Col>
      </Row>
    );
  };

  const deleteComment = (id, type, createdBy) => {
    Modal.error({
      title: `Are you sure you want to delete this ${type}?`,
      onOk() {
        removeComment(id, type, createdBy);
      },
      closable: true,
    });
    console.log("===== id =====", id);
  };

  const renderHeader = (id, type, userInfo) => {
    const { firstName, lastName, _id } = userInfo[0];
    let image = RecipeImage;
    if (type === "comment") {
      image = MessageImage;
    } else if (type === "exercise") {
      image = ExerciseImage;
    }
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <h5 style={{ marginRight: "10px" }}>{firstName + " " + lastName}</h5>
          <img
            style={{
              width: "20px",
              height: "20px",
              marginTop: "-2px",
            }}
            src={image}
          />
        </div>
        <div className="gx-pointer" onClick={() => deleteComment(id, type, _id)}>
          ❌
        </div>
      </div>
    );
  };

  const renderExerciseDetail = (data) => {
    if (data && data.length > 0) {
      return data.map((Item, index) => {
        const { _id, Description, type, userInfo } = Item;
        return (
          <Panel header={renderHeader(_id, type, userInfo)} key={`${index + 1}`} className="site-collapse-custom-panel">
            <p>{Description}</p>
          </Panel>
        );
      });
    }
  };
  if (deleteSuccess) {
    console.log("====== deleteSuccess", deleteSuccess);
    fetchDiscussionDetail();
    dispatch({ type: "ResetAll_State" });
  }

  const renderUserName = (data) => {
    if (data && data.length > 0) {
      let name = data[0].firstName + " " + data[0].lastName;
      return name;
    }
  };

  const renderData = () => {
    if (Loader) {
      return <Spin size="xl" />;
    } else {
      return (
        <>
          {renderTopicOfDayDetail()}

          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            //className="gx-mx-0 gx-d-flex"
          >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="comments-container">
                {CommentDetail &&
                  CommentDetail.length > 0 &&
                  CommentDetail.map((item, index) => {
                    return (
                      <div className="review-container gx-mb-2" style={{ width: "100%" }}>
                        <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                          <div className="gx-d-flex">
                            <Avatar
                              size={40}
                              icon={<UserOutlined />}
                              src={item.userInfo && item.userInfo[0] && item.userInfo[0].profileImageUrl}
                            />
                            <div className="gx-d-flex gx-flex-column gx-justify-content-center gx-ml-2 gx-mb-2">
                              <h5 className="gx-black-font  gx-mb-1 text-capitalize">
                                {renderUserName(item.userInfo)}
                              </h5>
                              <small className="gx-gray-font">
                                {item && item.createdAt && renderDate(item.createdAt)}
                              </small>
                            </div>
                          </div>
                          <div className="gx-pointer" onClick={() => error(index, item)}>
                            ❌
                          </div>
                        </div>
                        <div>{item && item.Description}</div>
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="site-collapse-custom-collapse"
                          >
                            {renderExerciseDetail(item.CommentsDetail)}
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                {CommentDetail && CommentDetail.length === 0 && <div>No Comments Yet!</div>}
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };

  return <Layout>{renderData()}</Layout>;
};

export default CommentDetail;
