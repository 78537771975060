import React from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import CommunityForm from "./CommunityForm";
import { Form } from "antd";
import moment from "moment";
const EditModal = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_CDiscussion_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();
  if (initialValues && initialValues.publishDate) {
    initialValues.publishDate = moment(initialValues.publishDate);
  }
  return (
    <FormModal
      modalTitle="Edit Discussion"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editCommunityDiscussion"
      ModalName="Edit_CDiscussion_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_CDiscussion_Modal ? Edit_CDiscussion_Modal : false}
      initialValues={initialValues}
      width={500}
      form={form}
      recordName="communityDiscussionList"
    >
      <CommunityForm />
    </FormModal>
  );
};

export default EditModal;
