import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import CategoryTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditModal from "./components/EditCategory";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddCategory from "./components/AddCategory";
import ShowModal from "components/Modal/ShowModal";
import { Image } from "antd";
const Category = () => {
  const columns = [
    {
      title: "No",
      render: (record) => <span>{record.selfCopingCategoryNo}</span>,
      key: "categoryNo",
      width: "150px",
    },
    {
      title: "Category",
      render: (record) => <span>{record.name}</span>,
      key: "categoryName",
      width: "250px",
    },
    {
      title: "Category Image",
      render: (record) => (
        <span>
          {" "}
          {record.categoryImage ? (
            <Image src={record.categoryImage} width="100px" height="80px" />
          ) : (
            "----"
          )}
        </span>
      ),
      key: "categoryName",
    },

    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_SelfCategory_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteSelfCopingCategory"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          recordName="selfCategoryList"
          oKText="Delete"
          title="Are you sure you want to delete this Category?"
          Icon="Delete"
        />
      </div>
    );
  };
  return (
    <Layout>
      <Widget styleName="mainTable" title="Category" extra={<AddCategory />}>
        <CategoryTable
          apiName="getSelfCopingCategory"
          recordName="selfCategoryList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditModal />
    </Layout>
  );
};
export default Category;
