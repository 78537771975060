import React from "react";
import Widget from "components/Widget";
import CategoryList from "components/Table/List";
import ActionMenu from "components/Table/Actions/ActionDropdown";
import Layout from "components/Layout";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditCategory from "./components/EditCategory";
import ShowModal from "components/Modal/ShowModal";
import AddNewCategory from "./components/AddCategory";
import SearchBox from "components/Filters/SearchBox";
import UploadCSV from "components/uploadCSV";
import { Tag } from "antd";
const Category = () => {
  const columns = [
    {
      title: "Category Name",
      render: (record) => <span>{record.categoryName}</span>,
      key: "Name",
    },

    {
      title: " ",
      render: (record) => <ActionMenu menu={renderActions(record)} />,
      key: "action",
      width: "50px",
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_Category_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteHealthCategory"
          recordName="categoryList"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Category?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <div className="gx-d-flex gx-flex-column">
            <span>Category</span>
            <a
              className="gx-m-0"
              href="https://painles.s3.amazonaws.com/1654281444496-categoryName-1%20-%20Sheet1.csv"
            >
              <Tag className="gx-m-0" color="blue">
                Download CSV Sample
              </Tag>
            </a>
          </div>
        }
        extra={
          <div className="gx-d-flex " style={{ gap: "20px" }}>
            <SearchBox
              apiName="getHealthCategory"
              recordName="categoryList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Category Name"
              width="200px"
            />

            <UploadCSV
              type="getHealthCategory"
              url="bulkImportSkills"
              table="HealthCategory"
              recordName="categoryList"
              cb="categoryName"
              uId="categoryId"
            />
            <AddNewCategory />
          </div>
        }
      >
        <CategoryList
          recordName="categoryList"
          apiName="getHealthCategory"
          columns={columns}
        />
      </Widget>
      <EditCategory />
    </Layout>
  );
};

export default Category;
