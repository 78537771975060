import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import GamificationForm from "./GamificationForm";

const AddGamification = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Gamification_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  return (
    <FormModal
      addButtonName="Add Gamification Point"
      modalTitle="Add Gamification Point"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addPoint"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      recordName="gamificationPointList"
      ModalName="Add_Gamification_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Gamification_Modal ? Add_Gamification_Modal : false}
      width={500}
      form={form}
    >
      <GamificationForm  />
    </FormModal>
  );
};

export default AddGamification;
