import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import ConservativeCareTable from "components/Table/List";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditConservativeCare from "./components/EditConservativeCare";
import TableAction from "components/Table/Actions/ActionDropdown";
import AddConservativeCare from "./components/AddConservativeCare";
import UploadCSV from "components/uploadCSV";
import ShowModal from "components/Modal/ShowModal";
import SearchBox from "components/Filters/SearchBox";
import { Tag } from "antd";

const Index = () => {
  const columns = [
    {
      title: "Name",
      render: (record) => <span>{record.conservativeCareName}</span>,
      key: "symptomName",
    },
    {
      title: "",
      render: (record) => <TableAction menu={renderActions(record)} />,
      key: "action",
      width: 50,
    },
  ];

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          record={record}
          modalName={"Edit_ConservativeCare_Modal"}
          buttonName="Edit"
        />
        <DeleteConfirm
          type="deleteConservativeCare"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="conservativeCareList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this cait specialist?"
          Icon="Delete"
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <div className="gx-d-flex gx-flex-column">
            <span>CAIT SPECIALIST</span>
            <a
              className="gx-m-0"
              href="https://painles.s3.amazonaws.com/1654280688368-conservativeCareName-1%20-%20Sheet1.csv"
            >
              <Tag className="gx-m-0" color="blue">
                Download CSV Sample
              </Tag>
            </a>
          </div>
        }
        extra={
          <div className="gx-d-flex" style={{ gap: "20px" }}>
            <SearchBox
              apiName="getConservativeCare"
              recordName="conservativeCareList"
              requestType="FetchRecord"
              customQuery={{ query: "search", key: "name" }}
              placeholder="Search By Name"
              width="200px"
            />
            <UploadCSV
              type="getConservativeCare"
              url="bulkImportSkills"
              table="ConservativeCare"
              recordName="conservativeCareList"
              cb="conservativeCareName"
              uId="conservativeCareId"
            />
            <AddConservativeCare />
          </div>
        }
      >
        <ConservativeCareTable
          apiName="getConservativeCare"
          recordName="conservativeCareList"
          scroll={true}
          pagination={true}
          columns={columns}
        />
      </Widget>
      <EditConservativeCare />
    </Layout>
  );
};
export default Index;
