import React from "react";
import { Form, Input, Row, Col, Select, InputNumber } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;
const RecordForm = () => {
  const curdPropsProps = useSelector((state) => state.CurdR);
  const { allTriggerTypes } = curdPropsProps;
  return (
    <Row className="gx-mx-0 gx-d-flex">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="triggerTypeId"
          label="Trigger Type"
          rules={[
            {
              required: true,
              message: "please enter the trigger type!",
            },
          ]}
        >
          <Select>
            {allTriggerTypes &&
              allTriggerTypes.map((item) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.triggerTypeName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="tipNo"
          label="Tip Number"
          rules={[
            {
              required: true,
              message: "please enter the tip number!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="wordExtremelyHigh"
          label="Category Text"
          rules={[
            {
              required: true,
              message: "please enter the extremely high!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tipDesc"
          label="Tip Text (A)"
          rules={[
            {
              required: true,
              message: "please enter the tip text!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tipDescB"
          label="Tip Text (B)"
          rules={[
            {
              required: true,
              message: "please enter the tip text!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tipDescC"
          label="Tip Text (C)"
          rules={[
            {
              required: true,
              message: "please enter the tip text!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="suggestedExNo"
          label="Suggested Exercise"
          rules={[
            {
              required: true,
              message: "please enter the suggested Exercise!",
            },
          ]}
        >
          <InputNumber min={1} max={6} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default RecordForm;
