import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "../../../../../components/Modal/FormModal";
import GamificationForm from "./GamificationForm";

const AddGamification = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Gamification_Setting_Modal, Loader } = faqProps;
  const [form] = Form.useForm();
  const [photoList, setPhotoState] = useState({ loading: false, imageUrl: "" });
  const [switchValue, setSwitchValue] = useState(false);
  return (
    <FormModal
      addButtonName="Add Gamification Medal"
      modalTitle="Add Gamification Medal"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addMadel"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      recordName="gamificationSettingList"
      ModalName="Add_Gamification_Setting_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={
        Add_Gamification_Setting_Modal ? Add_Gamification_Setting_Modal : false
      }
      
      width={500}
      form={form}
      extraFieldName='imageUrl'
      extraFieldValue={photoList.imageUrl}
    >
      <GamificationForm
        form={form}
        setPhotoState={setPhotoState}
        photoList={photoList}
        setSwitchValue={setSwitchValue}
        switchValue={switchValue}
      />
    </FormModal>
  );
};

export default AddGamification;
