import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import SymptomForm from "./SymptomForm";

const AddNewSymptom = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Add_Symptom_Modal, Loader } = faqProps;
  const [form] = Form.useForm();

  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  return (
    <FormModal
      addButtonName="Add New"
      modalTitle="Add Static"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      apiMethod="POST"
      addApiName="addStaticPageData"
      addApiRequest="AddRecord"
      apiLoader="StartSpinner"
      ModalName="Add_Symptom_Modal"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Add_Symptom_Modal ? Add_Symptom_Modal : false}
      recordName="staticPageList"
      width={550}
      form={form}
      className="doctor-profile-modal"
      extraFieldValue={photoList.imageUrl}
      extraFieldName={"displayImage"}
    >
      <SymptomForm
        form={form}
        photoList={photoList}
        setPhotoState={setPhotoState}
      />
    </FormModal>
  );
};

export default AddNewSymptom;
