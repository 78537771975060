import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import SpecialistPageForm from "./SpecialistPageForm";
const EditSpecialistPage = () => {
  const faqProps = useSelector((state) => state.CurdR);
  const { Edit_SpecialistPage_Modal, initialValues, Loader } = faqProps;
  const [form] = Form.useForm();

  return (
    <div>
      <FormModal
        modalTitle="Edit"
        submitButtonText="Update"
        cancelButtonText="Cancel"
        editApiRequest="EditRecord"
        apiLoader="StartSpinner"
        editApiMethod="PUT"
        editApiName="editStaticPage"
        ModalName="Edit_SpecialistPage_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Edit_SpecialistPage_Modal ? Edit_SpecialistPage_Modal : false}
        initialValues={initialValues}
        width={500}
        form={form}
        recordName="specialistPageList"
        extraFieldName="portal"
        extraFieldValue="specialist"
      >
        <SpecialistPageForm />
      </FormModal>
    </div>
  );
};

export default EditSpecialistPage;
